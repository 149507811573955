import request from '../../shared/lib/api';

const getAllProducts = (search) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/products`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
    params: { search },
  });
};
const getAllProductsByCategoryId = (categoryId) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/services/?category=${categoryId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};
const getProduct = (id) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/products/${id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const createProductCoupon = (userInputs) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/coupons`,
    method: 'POST',
    data: { ...userInputs },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const ProductsService = { getProduct, getAllProducts, createProductCoupon,getAllProductsByCategoryId };

export default ProductsService;
