import { LeftOutlined, RightOutlined,EditOutlined } from '@ant-design/icons';
import groupBy from 'lodash/groupBy';
import {useHistory} from 'react-router-dom';

import {
  Button,
  Form,
  message,
  Space,
  Steps,
  Typography,
  DatePicker,Tag,Text
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import PageTitle from '../../../components/layout/page-title/PageTitle';
import LifeMasteryService from '../../../services/api/LifeMasteryService';
import LifeMasteryHistoryTable from './components/HistoryTable';
import { Step1, Step2, Step3, Step4, Step5, Step6, Step7 } from './steps';
import moment from 'moment';

import './styles.less';

const { Step } = Steps;

const MyLifeMasteryPage = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentGoalId,setGoalId] = useState(props.location.state.goalId);
  const [title,setGoalTitle] = useState(props.location.state.goalTitle);
  const [currentData, setCurrentData] = useState({});
  const [date, setDate] = useState(moment());
  const [pleaseUpdate, setPleaseUpdate] = useState({});

  const [currentStep, setCurrentStep] = useState(0);

  const history = useHistory();


  useEffect(() => {
    setLoading(true);
    // console.log('goalid',props.location.state.goalId);
    // setGoalId(props.location.state.goalId);
    // setGoalTitle(props.location.state.goalTitle);
    LifeMasteryService.getAllLifeMasteriesWithGoalId(`${currentStep + 1}`,currentGoalId)
      .then((res) => res.data.data)
      .then((data) => {
        const withKeyData = data.lifeMastery.map((item) => ({
          ...item,
          key: item.id,
        }));
        const groupedByDay = groupBy(withKeyData, (item) => {
          return moment(item.createdAt);
        });
        

     
        checkExist(groupedByDay, date);
      })
      .finally(() => setLoading(false));
  }, [currentStep, date]);

  const checkExist = (data) => {
    let exist;


    Object.keys(data).forEach((key) => {
      if (!exist) {
        exist = moment(moment(date)).isSame(moment(data[key][0].createdAt));

        // exist = true;
        if (exist) {
          
          setFormData(data[key]);
          setEditMode(true);
          setCurrentData(data[key]);
        } else {// NOT EXIST
          
          // let keyname = Object.keys(data)[0];
          // setFormData(data[key]);
          // setEditMode(true);

          const hasKeys = !!Object.keys(data).length;

          if(hasKeys)
          {
           const firstKey =  Object.keys(data)[0];
           setFormData(data[firstKey]);
           setEditMode(true);
           setCurrentData(data[firstKey]);
          }
  
     
        }
      }
    });
  };

  const steps = useMemo(
    () => [
      {
        title: 'Goal Setting',
        description: 'Where is your next Destination?',
        form: Step1,
      },
      {
        title: 'Self-Assessment',
        description: 'Where are you NOW in your Life Journey?',
        form: Step2,
      },
      {
        title: 'Planning & Guidance',
        description:
          'How will you get there? Who will guide you to your destination?',
        form: Step3,
      },
      {
        title: 'Declaration Statement',
        description:
          'If you want to significantly increase your chance of success, make sure to complete your Declaration Statement.',
        form: Step4,
      },
      {
        title: 'Execution',
        description:
          "STEPS 1-4 are all about Planning and Preparation. Now it's time to execute and track your progress.",
        form: Step5,
      },
      {
        title: 'Completion',
        description: 'Answer these questions upon completion of your program',
        form: Step6,
      },
      {
        title: 'Review',
        description: 'It is now time for Self-Reflection',
        form: Step7,
      },
    ],
    [],
  );

  const dateIsUpdated = (date) => {
    setDate(date);
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onChange = (current) => {
    setCurrentStep(current);
  };

  const submitCallback = steps[currentStep].callback
    ? steps[currentStep].callback
    : (values) => {
        setLoading(true);
        if (editMode) {
          let softDelete = currentData[0].softDelete;
          // LifeMasteryService.editLifeMastery({ lifeMastries: values, date,softDelete, MyGoalsId:currentGoalId })
          //   .then(() => {
          //     message.success('Saved successfully.');
          //   })
          //   .finally(() => {
          //     setLoading(false);
          //     form.resetFields();
          //     setDate(moment());
          //     setPleaseUpdate({});
          //   });
          const currenDateNow = moment();
          LifeMasteryService.addLifeMastery({ lifeMastries: values, currenDateNow,MyGoalsId:currentGoalId })
          .then(() => {
            message.success('Saved successfully.');
          })
          .finally(() => {
            setLoading(false);
            form.resetFields();
            setDate(moment());
            setPleaseUpdate({});
          });
        } else {
          LifeMasteryService.addLifeMastery({ lifeMastries: values, date,MyGoalsId:currentGoalId })
            .then(() => {
              message.success('Saved successfully.');
            })
            .finally(() => {
              setLoading(false);
              form.resetFields();
              setDate(moment());
              setPleaseUpdate({});
            });
        }
      };

  const onFinish = (values) => {
    submitCallback(values);
  };

  const preButton = (
    <Button
      type="default"
      onClick={() => prev()}
      icon={<LeftOutlined />}
      disabled={!(currentStep > 0)}
    />
  );

  const nextBtn = (
    <Button
      type="default"
      onClick={() => next()}
      icon={<RightOutlined />}
      disabled={!(currentStep < steps.length - 1)}
    />
  );

  const editingAlert = (editMode ? <Tag icon={<EditOutlined />} color="orange" style={{marginLeft: '2%', marginBottom:'2%'}}>
  You are currently editing previous data
  </Tag> : null
  )

  const addNewBtn = (
    <Button
      type="primary"
      style={{marginLeft: '2%', marginBottom:'5%'}}
      onClick={() => {
        form.resetFields();
        setCurrentData({});
        setDate(moment());
        setEditMode(false);

      }}
      // icon={<LeftOutlined />}
      // disabled={}
    >
      Add New
    </Button>
  );
  const backToGoalsBtn = (
    <Button
    
      type="primary"
      style={{marginLeft: '2%', marginBottom:'5%'}}
      icon={<LeftOutlined />}
      onClick={() => {
        history.push({
          pathname: 'my-goals',
        })
      }}

    >
      Back to Goal
    </Button>
  );

  const submitBtn = (
    <Button type="primary" htmlType="submit" loading={loading}>
      Submit
    </Button>
  );


  const StepFormItem = steps[currentStep]?.form;

  const setFormData = (data) => {
    form.resetFields();

    const formattedData = data.map((data) => {
      const { answer, step, question } = data;

      return { [`${question}-${step}`]: JSON.parse(answer).value };
    });
    formattedData.forEach((item) => form.setFieldsValue(item));
  };

  return (
    <>
      <PageTitle title={`Goal: ${title}/My Life Mastery Navigator`} />
      {/* {addNewBtn} */}

      {editingAlert} 
      {loading ? null : (
        <DatePicker
          value={date}
          style={{ marginBottom: '5%', display: 'none' }}
          dateRender={(current) => {
            const style = {};

            return (
              <div className="ant-calendar-date" style={style}>
                {current.date()}
              </div>
            );
          }}
          defaultValue={date}
          onChange={dateIsUpdated}
        />
      )}

      <Steps current={currentStep} onChange={onChange} progressDot>
        {steps.map((item, index) => (
          <Step key={item.title} title={`${index + 1}. ${item.title}`} />
        ))}
      </Steps>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <div className="steps-content">
          <Typography.Text className="steps-description" type="secondary">
            {steps[currentStep].description}
          </Typography.Text>
          <div style={{ paddingTop: '20px' }}>
            {<StepFormItem form={form} />}
          </div>
        </div>


        <div className="steps-action" style={{ marginBottom: '20px' }}>
          <Space>
            {preButton}
            {nextBtn}
            {submitBtn}
          </Space>
          {backToGoalsBtn}

        </div>

        {/* <LifeMasteryHistoryTable
          step={currentStep}
          onEditClick={setFormData}
          onChangeDate={setDate}
          onSetCurrentData = {setCurrentData}
          goalId = {currentGoalId}
          UpdateComponent = {pleaseUpdate}
        /> */}
      </Form>

    </>
  );
};

export default MyLifeMasteryPage;
