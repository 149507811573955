/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  InfoOutlined,
  AimOutlined,
  HeatMapOutlined,
  HeartOutlined,
  ClusterOutlined,
  StarOutlined,
  AppstoreAddOutlined,
  YoutubeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CheckCircleOutlined,
  BarChartOutlined,
  BookOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Row,
  Col,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useState } from 'react';

import { Header } from 'antd/lib/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import { logoutUser } from '../../../store/actions/authActions';
import './styles.less';
import background from './soul-1200-op.jpg';

const { Sider } = Layout;
const { Content, Footer } = Layout;
const SideBar = () => {
  const location = useLocation();
  const [backtohome, setBacktohome] = useState(true);

  const myLifeMasteryMenu =
    location.pathname == '/my-life' ? (
      <Menu.Item key="/my-life">
        <Link to="/my-life" style={{ pointerEvents: 'none', marginLeft: '5%' }}>
          My Life Mastery Navigator
        </Link>
      </Menu.Item>
    ) : null;

  return (
    <Sider
      width="250"
      style={{ boxShadow: '14px -1px 12px -17px rgba(0,0,0,0.75)' }}
      collapsible
      defaultCollapsed={true}
      className="sidebar"
      onCollapse={(collapsed) => {
        setBacktohome(collapsed);
        // alert("COL" );
      }}
    >
      <Row justify="center" style={{ padding: '0.7em', paddingTop: '3em' }}>
        {/* <Space size={[0]} align="center" direction="vertical" wrap> */}
        <a href="https://heatwellbeing.com/" style={{ textAlign: 'center' }}>
          <img
            src={Logo}
            className="logo"
            alt="logo"
            style={{ width: '70%' }}
          />
        </a>
        {backtohome ? (
          <></>
        ) : (
          <a
            href="https://heatwellbeing.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ padding: '1em', paddingTop: '1.5em' }}
          >
            <Button type="primary" size="medium">
              BACK TO HOME
            </Button>
          </a>
        )}

        {/* </Space> */}
      </Row>
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
        <Menu.Item icon={<BarChartOutlined />} key="/">
          <Link to="/">Dashboard</Link>
        </Menu.Item>

        <Menu.SubMenu icon={<BookOutlined />} title="My Life Book">
          <Menu.Item icon={<CheckCircleOutlined />} key="/my-goals">
            <Link to="/my-goals">My Goals</Link>
          </Menu.Item>
          {myLifeMasteryMenu}
          <Menu.Item icon={<HeatMapOutlined />} key="/core-values">
            <Link to="/core-values">My Core Values</Link>
          </Menu.Item>
          <Menu.Item icon={<ClusterOutlined />} key="/my-road">
            <Link to="/my-road">My Life Map (Ranks)</Link>
          </Menu.Item>
          <Menu.Item icon={<AimOutlined />} key="/wheel">
            <Link to="/wheel">My Life Target</Link>
          </Menu.Item>
 
        </Menu.SubMenu>
        <Menu.Item icon={<HeartOutlined />} key="/my-health-wellness">
            <Link to="/my-health-wellness">My Health & Wellness Book</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const TopMenu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const { loading, user } = auth;

  const safeuser = user || {
    rank: '',
    plus: '',
    points: 0,
    credit: 0,
    username: '',
    avatarUrl: '',
    role: '',
    serial: '',
  };

  const onLogoutClick = () => {
    window.location.href = 'https://heatwellbeing.com';
    dispatch(logoutUser());
  };

  const userMenu = (
    <Menu mode="horizontal">
      <Menu.Item key="/profile/serial">
        <Typography.Text>ID:</Typography.Text>{' '}
        <Typography.Text strong>{safeuser.serial + 1000}</Typography.Text>
      </Menu.Item>
      <Menu.Item key="/profile/role">
        <Typography.Text>Role:</Typography.Text>{' '}
        <Typography.Text strong>{safeuser.role}</Typography.Text>
      </Menu.Item>

      <Menu.Item key="1">
        <Typography.Text>Rank: </Typography.Text>{' '}
        <Typography.Text strong>
          {loading ? <Spin /> : safeuser.rank}
        </Typography.Text>
        <Typography.Text strong> {safeuser.plus ? '+' : ''}</Typography.Text>
      </Menu.Item>
      <Menu.Item key="/profile/points">
        <Link to="/profile/points">
          HEAT Points:{' '}
          {loading ? (
            <Spin />
          ) : (
            <Typography.Text strong>{safeuser.points}</Typography.Text>
          )}
        </Link>
      </Menu.Item>
      <Menu.Item key="/profile/credit">
        <Link to="/profile/points">
          Credit:{' '}
          {loading ? (
            <Spin />
          ) : (
            <Typography.Text strong>
              ${safeuser.credit?.toFixed(2)}
            </Typography.Text>
          )}
        </Link>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="2" onClick={(e) => {}} icon={<UserOutlined />}>
        <Link to="/Profile">Profile</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="3"
        onClick={onLogoutClick}
        icon={<LogoutOutlined />}
        danger
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const mainMenu = (
    <Menu theme="dark" mode="horizontal" style={{ backgroundColor: 'grey' }}>
      {/* <Menu.SubMenu key="Submenu1" title="ABOUT US"  icon={<InfoOutlined />}>
          <Menu.Item key="one">
            <Link type="primary" to={{pathname: "https://heatwellbeing.com/home/about/" }} target="_blank">
              PHILOSOPHY
            </Link>

          </Menu.Item>
          <Menu.Item key="two">
            <Link type="primary" to={{pathname:"https://heatwellbeing.com/dr-francis-vala/"}} target="_blank">
              TEAM
            </Link>
          </Menu.Item>
        </Menu.SubMenu> */}

      <Menu.SubMenu
        title="Market Place"
        style={{ width: '11em' }}
        icon={<DownOutlined />}>
        <Menu.Item key="/products" icon={<ShoppingCartOutlined />}>
          <Link to="/products">Products</Link>
        </Menu.Item>

        <Menu.SubMenu
        title="Services"
        style={{ width: '11em' }}
        icon={<DownOutlined />}>
        <Menu.Item key="32">
          <Link to="/services?category=32">Dietician / Nutritionist</Link>
        </Menu.Item>
        <Menu.Item key="31">
          <Link to="/services?category=31">Skin care</Link>
        </Menu.Item>
        <Menu.Item key="28">
          <Link to="/services?category=28">Weight Loss</Link>
        </Menu.Item>
    

        </Menu.SubMenu>

      </Menu.SubMenu>

      {/* <Menu.Item
       style={{ width: '11em' }}

      icon={<AppstoreAddOutlined />}>
        <Link
          type="primary"
          to={{ pathname: 'https://heatwellbeing.com/providers/' }}
          target="_blank"
        >
          Providers
        </Link>
      </Menu.Item> */}
      {/* <Menu.SubMenu key="submenutwo" title="EVENTS" icon={<StarOutlined />}>
          <Menu.Item key="three">
            <Link type="primary" to={{pathname:"https://heatwellbeing.com/past-events/"}} target="_blank">
              PAST EVENTS
            </Link>
          </Menu.Item>
          <Menu.Item key="four">
            <Link type="primary" to={{pathname:"https://heatwellbeing.com/upcoming-events/"}} target="_blank">
              UPCOMING EVENTS
            </Link>
          </Menu.Item>
        </Menu.SubMenu> */}
      {/* <Menu.Item icon={<YoutubeOutlined />}>
        <Link type="primary" to={{pathname:"https://heatwellbeing.com/media/"}} target="_blank">
          MEDIA
        </Link>
      </Menu.Item> */}
      <Menu.SubMenu
        title="Resources"
        style={{ width: '11em' }}
        icon={<DownOutlined />}
      >

        <Menu.SubMenu title="Calculating and screening tools">
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/bac-calculator.html',
              }}
              target="_blank"
            >
              Blood Alcohol Concentration (BAC) Calculator
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/bmr-calculator.html',
              }}
              target="_blank"
            >
              BMR Calculator
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/bmi-calculator.html',
              }}
              target="_blank"
            >
              BMI Calculator
            </Link>
          </Menu.Item>
          {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/diabetic.html"}}target="_blank">CVD risk calculator (Framingham)</Link></Menu.Item> */}

          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/body-fat-calculator.html',
              }}
              target="_blank"
            >
              Body Fat Calculator
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://www.calculator.net/body-type-calculator.html',
              }}
              target="_blank"
            >
              Body Type Calculator
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://bcrisktool.cancer.gov/calculator.html',
              }}
              target="_blank"
            >
              Breast cancer risk assessment tool
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/calorie-calculator.html',
              }}
              target="_blank"
            >
              Calorie Calculator{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://www.calculator.net/calories-burned-calculator.html',
              }}
              target="_blank"
            >
              Calories Burned Calculator{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://clincalc.com/cardiology/stroke/chads.aspx',
              }}
              target="_blank"
            >
              CHADS2 Calculator for Guiding Antithrombotic Treatment in Atrial
              Fibrillation{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.mycanceriq.ca/Cancers/Cervical',
              }}
              target="_blank"
            >
              Cervical cancer risk assessment tool{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{ pathname: 'https://ccrisktool.cancer.gov/calculator.html' }}
              target="_blank"
            >
              Colorectal Cancer Risk Assessment Tool
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/due-date-calculator.html',
              }}
              target="_blank"
            >
              Due Date Calculator{' '}
            </Link>
          </Menu.Item>
          {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/index.html?sex=F&age=64"}}target="_blank">Framingham CVD calculator</Link></Menu.Item> */}
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://frax.shef.ac.uk/FRAX/tool.aspx?country=19',
              }}
              target="_blank"
            >
              FRAX, Osteoporotic fracture risk calculator
            </Link>
          </Menu.Item>
          {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/GeneralCalculators.jsp"}}target="_blank">General conversions</Link></Menu.Item> */}
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://clincalc.com/cardiology/anticoagulation/hasbled.aspx',
              }}
              target="_blank"
            >
              HAS-BLED Calculator for Assessing Bleeding Risk in Atrial
              Fibrillation{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://www.mycanceriq.ca/Cancers/Kidney',
              }}
              target="_blank"
            >
              Kidney cancer risk assessment tool{' '}
            </Link>
          </Menu.Item>
          {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/kidneyFailureRiskCalculator/kidneyFailureRiskCalculator.jsp?sex=F&age=64&demographic_no=1327"}}target="_blank">Kidney failure risk calculator </Link></Menu.Item> */}
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://www.calculator.net/lean-body-mass-calculator.html',
              }}
              target="_blank"
            >
              Lean Body Mass Calculator{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://analysistools.cancer.gov/lungCancerRiskAssessment/#/',
              }}
              target="_blank"
            >
              Lung Cancer Risk Assessment Tool
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/tdee-calculator.html',
              }}
              target="_blank"
            >
              Macronutrient Calculator
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.mycanceriq.ca/Cancers/Melanoma',
              }}
              target="_blank"
            >
              Melanoma risk assessment tool
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://www.calculator.net/lean-body-mass-calculator.html',
              }}
              target="_blank"
            >
              Osteoporotic Fracture risk calculator{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname:
                  'https://www.calculator.net/ovulation-calculator.html',
              }}
              target="_blank"
            >
              Ovulation Calculator{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/period-calculator.html',
              }}
              target="_blank"
            >
              Period Calculator{' '}
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.mcw.edu/calculators/pregnancy-date',
              }}
              target="_blank"
            >
              Pregnancy calculator
            </Link>
          </Menu.Item>
          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/macro-calculator.html',
              }}
              target="_blank"
            >
              Simple calculator
            </Link>
          </Menu.Item>

          <Menu.Item>
            {' '}
            <Link
              to={{
                pathname: 'https://www.calculator.net/tdee-calculator.html',
              }}
              target="_blank"
            >
              Total Daily Energy Expenditure (TDEE) Calculator
            </Link>
          </Menu.Item>

          {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/index.html?sex=F&age=48"}}target="_blank">Framingham </Link></Menu.Item> */}
          {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/kidneyFailureRiskCalculator/kidneyFailureRiskCalculator.jsp?sex=F&age=48&demographic_no=6955" }}target="_blank">Kidney failure risk calculator</Link></Menu.Item> */}
        </Menu.SubMenu>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <Row justify="space-between" align="middle">
      {mainMenu}

      <Menu
        mode="horizontal"
        style={{ padding: '20px 0', backgroundColor: 'transparent' }}
      >
        <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
          <Space>
            <Dropdown
              overlay={userMenu}
              trigger={['click']}
              placement="bottomRight"
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {safeuser.username} <DownOutlined />
              </a>
            </Dropdown>
            <Avatar
              shape="circle"
              size="large"
              src={safeuser.avatarUrl}
              icon={<UserOutlined />}
            />
          </Space>
        </div>
      </Menu>
    </Row>
  );
};

const AppLayout = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Layout id="app-layout" style={{ minHeight: '100vh' }}>
      {auth.isLoggedIn ? <SideBar /> : null}
      <Layout className="site-layout">
        <Content style={{ margin: '16px' }}>
          {auth.isLoggedIn ? <TopMenu user={auth.user} /> : null}
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          &copy; Copyright 2021 HEAT
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
