import request from '../../shared/lib/api';

const getAllQuestions = (searchTerm, groupId) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/questions`,
    method: 'GET',
    params: {
      ...(searchTerm && { term: searchTerm }),
      ...(groupId && { groupId }),
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const getAllQuestionGroups = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/questionGroups`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const QuestionService = {
  getAllQuestions,
  getAllQuestionGroups,
};

export default QuestionService;
