import { Button, Checkbox, Form, Input, Modal, Select, Space } from 'antd';
import { useState } from 'react';
import {
  FullWidthInputNumber as InputNumber,
  IsoStringDatePicker,
} from '../../../../components/wrappers/inputs';
import MyLifeWheelPage from '../../my-life-wheel';

const { Option } = Select;

const Step1 = ({ form }) => {
  const [visible, setVisible] = useState(false);

  const onHeatChange = (data) => {
    const values = Object.values(data);
    const sum = values.reduce((a, b) => a + b, 0);

    const notZeros = Object.keys(data).filter((key) => data[key]);

    const result = `${sum}/${notZeros.length * 10}`;

    form.setFieldsValue({
      'b-1': result,
    });
  };

  // const colorOptions = colorMap.map((color) => (
  //   <Option key={color.name} value={color.name}>
  //     {color.name}
  //   </Option>
  // ));
  // const colorSelector = <Select>{colorOptions}</Select>;

  return (
    <>
      <Form.Item
        name="a-1"
        label="a) Choose the Domain/Sub-domain you want to improve (Short Term Goal) ?"
        rules={[{ required: true }]}
      >
        <Select>
          <Option value="Body">Body</Option>
          <Option value="Mind">Mind</Option>
          <Option value="Soul">Soul</Option>
          <Option value="Social Aspects - EDUCATION">
            Social Aspects - EDUCATION
          </Option>
          <Option value="Social Aspects - MONEY">
            Social Aspects - Money/Income
          </Option>
          <Option value="Career/Occupation">
            Social Aspects - Career/Occupation
          </Option>
          <Option value="Social Aspects - LOVE">Social Aspects - LOVE</Option>
          <Option value="Social Aspects - SEXUALITY">
            Social Aspects - SEXUALITY
          </Option>
          <Option value="Social Aspects - ENVIRONMENT">
            Social Aspects - ENVIRONMENT
          </Option>
          <Option value="Social Aspects - CONFLICT">
            Social Aspects - CONFLICT
          </Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="b-1"
        label="b) What is the current baseline Human Potential Score (HPS) in your target Domain/Sub-domain?"
        extra={
          <>
            <Button type="link" onClick={() => setVisible(true)}>
              Go to My H.E.A.T Life Target
            </Button>
          </>
        }
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="c-1"
        label="c) What is the desired HPS in your target Domain/Sub-domain after the program completion?"
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="d-1"
        label="d) What does the desired HPS mean to you? Describe what would you like to achieve after the program completion?"
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        name="f-1"
        label="e) Does your goal meet the SMART criteria? ?"
      >
        <Checkbox.Group>
          <Space direction="vertical">
            <Checkbox value="Specific">Specific</Checkbox>
            <Checkbox value="Measurable">Measurable</Checkbox>
            <Checkbox value="Achievable">Achievable</Checkbox>
            <Checkbox value="Realistic">Realistic</Checkbox>
            <Checkbox value=" Timely"> Timely</Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>

      {/* <Form.Item
        name="g-1"
        label="g) (for LEVEL 2) What is your current Rank (Band colour)?"
      >
        {colorSelector}
      </Form.Item> */}

      {/* <Form.Item
        name="h-1"
        label="h) (for LEVEL 2) What is your next Rank?"
        extra={
          <>
            <Text type="secondary">To find out your next rank,</Text>
            <Button type="link">
              <Link to="/my-road">
                Go to My Ultimate Life Mastery Road Map (click)
              </Link>
            </Button>
          </>
        }
      >
        {colorSelector}
      </Form.Item> */}

      {/* <Form.Item
        name="i-1"
        label="i) (for LEVEL 2) Have you updated your Ultimate Life Mastery Road Map?"
        extra={
          <>
            <Text type="secondary">To find out your next rank,</Text>
            <Button type="link">
              <Link to="/my-road">
                Go to My Ultimate Life Mastery Road Map (click)
              </Link>
            </Button>
          </>
        }
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Space>
        </Radio.Group>
      </Form.Item> */}

      <Modal
        title="H.E.A.T Target Life"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width="90vw"
        footer={false}
      >
        <MyLifeWheelPage
          onChange={onHeatChange}
          setVisible={setVisible}
          modal
        />
      </Modal>
    </>
  );
};

export default Step1;
