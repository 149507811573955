/* eslint-disable no-unused-vars */
import { Badge, Table, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import PageTitle from '../../../components/layout/page-title/PageTitle';
import PointService from '../../../services/api/PointsService';

const UserPointsPage = () => {
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState([]);

  const getPoints = async () => {
    setLoading(true);
    const res = await PointService.getAllPoints();
    setPoints(res.data.data.points.map((rest) => ({ ...rest, key: rest.id })));
    setLoading(false);
  };

  useEffect(() => {
    getPoints();
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        render: (_item, _record, index) => index + 1,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (name) => {
          return <Tag color="blue">{name}</Tag>;
        },
      },
      {
        title: 'Point',
        dataIndex: 'point',
        width: '40%',
        render: (point) => {
          return (
            <Badge
              className="site-badge-count-109"
              count={point}
              style={{ backgroundColor: '#52c41a' }}
              overflowCount={100000000}
            />
          );
        },
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        width: '15%',
        render: (time) => {
          return moment(time).format('YYYY-MM-DD hh:mm:ss');
        },
      },
    ],
    [],
  );

  return (
    <>
      <PageTitle title="HEAT Points" />
      <Table dataSource={points} columns={columns} loading={loading} />
    </>
  );
};

export default UserPointsPage;
