const Step2 = () => (
  <>
    <ul>
      <li>Human Potential Score (HPS) at least 45</li>
      <li>GREEN at least 3</li>
      <li>Already a White Band member</li>
    </ul>
  </>
);

export default Step2;
