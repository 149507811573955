import { Button, Card, Carousel, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ProductCard = (props) => {
  // console.log(props);
  const { user } = useSelector((state) => state.auth);
  const {
    id,
    name,
    regular_price,
    price,
    images,
    onButtonClick,
    showButton = true,
  } = props;

  const imagesElement = images.map((img) => (
    <div>
      <Link to={`/products/${id}`}>
        <img
          style={{ height: '300px', width: '100%', objectFit: 'contain' }}
          alt={img.name}
          src={img.src}
        />
      </Link>
    </div>
  ));

  // const safePrice = props && props.price ? props.price : 0;
  const safePrice = props && props.price ? props.price : 0;

  const maxOfferCanApply = Math.min(safePrice * 0.1, user.credit);
  const priceComp =
    regular_price == price ? (
      <Typography.Text
        style={{
          color: 'black',
          fontSize: '1.2rem',
          paddingRight: '10px',
        }}
      >
        {regular_price}$
      </Typography.Text>
    ) : (
      <Row>
      <Typography.Text
      style={{
        color: 'red',
        textDecoration :'line-through',
        fontSize: '1.2rem',
        paddingRight: '10px',

      }}
    >
      {regular_price}$
    </Typography.Text>
      <Typography.Text
        style={{
          color: '#3f8600',
          fontSize: '1.3rem',
        }}
        strong
      >
        {price}$
      </Typography.Text>
      </Row>
    );
    
  const footer = showButton ? (
    <Row style={{ marginTop: '20px' }} justify="space-between" align="middle">
      {priceComp}

      <Button
        key="primary-button-buy"
        type="primary"
        onClick={() => onButtonClick(props)}
      >
        Buy
      </Button>
    </Row>
  ) : null;

  return (
    <Card
      key={id}
      style={{ height: '100%' }}
      cover={<Carousel autoplay>{imagesElement}</Carousel>}
    >
      <Link to={`/products/${id}`}>
        <Card.Meta title={name} />
      </Link>

      {footer}
    </Card>
  );
};

export default ProductCard;
