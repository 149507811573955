const Step3 = () => (
  <>
    <ul>
      <li>Human Potential Score (HPS) at least 50</li>
      <li>GREEN at least 4</li>
      <li>Already be a Red Band member</li>
    </ul>
  </>
);

export default Step3;
