
import { Button, Collapse, Table ,Space} from 'antd';
import {  EditOutlined,DeleteOutlined } from '@ant-design/icons';
import MyGoalsService from '../../../../services/api/MyGoalsService';

import { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import { EyeOutlined} from '@ant-design/icons';
import moment from 'moment';

const { Panel } = Collapse;

const MyGoalsListTable = ({  onEditClick, wholedata,pleaseUpdate }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  useEffect(() => {
    
    setData(wholedata);
    console.log(wholedata);
  }, [wholedata]);

  const routeChange = (record) =>{ 
    history.push({
      pathname: 'my-life',
      state:{goalId:record?.id, goalTitle : record?.title}
    })
  }
  const deleteGoal = async (record) =>{ 
    setLoading(true);
    await MyGoalsService.deleteGoal({id:record.id, userId:record.userId}).then(()=> {
      setLoading(false);
      pleaseUpdate({});
    });  

  }


  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      rowScope: 'row',
      render: (id, record, index) => { ++index; return index; },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: '20%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '30%',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      width: '30%',
      render: (_, key) => {
        return (
          moment(_).format('YYYY-MM-DD hh:mm:ss')
        );
        }
    },
    {
      title: 'Last updated',
      dataIndex: 'updatedAt',
      width: '30%',
      render: (_, key) => {
        return (
          moment(_).format('YYYY-MM-DD hh:mm:ss')
        );
        }
    },
    {
      
      title: 'Start',
      dataIndex: 'start',
      width: '20%',
      render: (_, key) => {
        return (
          moment(_).format('YYYY-MM-DD hh:mm:ss')
        );
      },
    },
    {
      title: 'End',
      dataIndex: 'deadline',
      width: '20%',
      render: (_, key) => {
        return (
          moment(_).format('YYYY-MM-DD hh:mm:ss')
        );
      },
    },
 
   
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      render: (id, record, index) => { 
        switch (record.status) {
          case 'todo':
            return 'To Do'
            break;
          case 'inprogress':
            return 'In Progress'
            break;
          case 'done':
            return 'Done'
            break;
        
          default:
            break;
        }
       },

      
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "actions",
      render: (id, record) => {
        return (
          <Space>
       
            <Button
              type="primary"
              onClick={(e)=> routeChange(record)}
              icon={<EyeOutlined />}
              size="small"
            />
            <Button
              type="primary"
              onClick={() => {
                onEditClick(record);
              } }
              icon={<EditOutlined />}
              size="small"
            />
              <Button
                type="primary"
                onClick={() => {
                   deleteGoal(record);
                }}
                icon={<DeleteOutlined />}
                size="small"
              />
            
          </Space>
        );
      },
    },
  ];

  return (
    <Collapse accordion defaultActiveKey={['1']} style={{marginTop : '5%'} }>
      <Panel header="My Goals List" key="1" >
        <Table
          loading={loading}
          bordered
          dataSource={data}
          columns={columns}
        />
      </Panel>
    </Collapse>
  );
};

export default MyGoalsListTable;
