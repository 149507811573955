import { Form, Select } from 'antd';

const { Option } = Select;

const Step3 = ({ values }) => (
  <>
    <Form.Item
      name="topCoreValue"
      label="What is the #1 Core Value at this stage of your life?"
      rules={[{ required: true }]}
    >
      <Select>
        {values['topFiveCoreValues']
          ? values['topFiveCoreValues'].map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))
          : null}
      </Select>
    </Form.Item>
  </>
);

export default Step3;
