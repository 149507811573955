import { Form, Radio, Space, Input, Typography, Button, Select } from 'antd';
import { useState } from 'react';
import MyCareTeamModal from '../modals/my-care-team-modal';

const { Text } = Typography;
const { Option } = Select;

const Step3 = ({ form }) => {
  const [visible, setVisible] = useState(false);
  const [accountabilityBuddies, setAccountabilityBuddies] = useState([]);

  const handleOk = (vals) => {
    setAccountabilityBuddies(vals['accountability-buddies'] || []);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Form.Item
        name="a-3"
        label="a) How will you achieve your goal? What is your plan (Action Items)? What resources do you need?"
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        name="b-3"
        label="b) Will you work with a Coach/Mentor to reach your destination?"
        extra={
          <Text type="secondary">
            We highly recommend you work with a Coach/Mentor. However, you can
            also use our tools and resources if you wish to DIY.
          </Text>
        }
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues['b-3'] !== currentValues['b-3']
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('b-3') === 'Yes' ? (
            <Form.Item
              name="c-3"
              label="c) Who is your Coach/Mentor?"
              rules={[{ required: true }]}
              extra={
                <Text type="secondary">
                  If you don't have one, we recommend one of our H.E.A.T
                  Certified Faculty Members (contact us to learn more).
                </Text>
              }
            >
              <Input />
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item
        name="d-3"
        label="d) Who is your Accountability Buddy (AB)?"
        extra={
          <>
            <Text type="secondary">To update</Text>
            <Button type="link" onClick={() => setVisible(true)}>
              Go to My Care Team
            </Button>
          </>
        }
      >
        <Select>
          {accountabilityBuddies.map((ab) => (
            <Option key={ab} value={ab}>
              {ab}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <MyCareTeamModal
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default Step3;
