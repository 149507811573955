import { Col, Collapse, Image, Row } from 'antd';
import bodyImage from '../../../../assets/body.png';
import careerImage from '../../../../assets/career.png';
import educationImage from '../../../../assets/education.png';
import environmentImage from '../../../../assets/environment.png';
import loveImage from '../../../../assets/love.png';
import mindImage from '../../../../assets/mind.png';
import moneyImage from '../../../../assets/money.png';
import peaceImage from '../../../../assets/peace.png';
import sexualityImage from '../../../../assets/sexuality.png';
import soulImage from '../../../../assets/soul.png';

const { Panel } = Collapse;

const WheelHelpers = () => {
  const images = [
    bodyImage,
    mindImage,
    soulImage,
    loveImage,
    sexualityImage,
    moneyImage,
    careerImage,
    environmentImage,
    educationImage,
    peaceImage,
  ];

  const imagesElements = images.map((imgSrc) => {
    return (
      <Col span={8}>
        <Image src={imgSrc} width="100%" />
      </Col>
    );
  });

  return (
    <Collapse accordion style={{ marginTop: '20px' }}>
      <Panel header="Guidelines" key="1">
        <Row>{imagesElements}</Row>
      </Panel>
    </Collapse>
  );
};

export default WheelHelpers;
