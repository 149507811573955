import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { loginUserWithReferal } from '../../../store/actions/authActions';
import useQuery from '../../../hooks/useQuery';

const NormalLoginPageReferal = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    
    if (auth.isLoggedIn) {
      const redirectTo = auth?.redirectTo ? auth?.redirectTo : '/';
      history.push(location.state?.form.pathname || redirectTo);
    }
    
  }, [auth, location, history]);

  const onFinish = (values) => {
    const referalkey  = query.get('referalkey');
    values.referalCode = referalkey;
    dispatch(loginUserWithReferal(values));
  };

  return (
    <>
      <Row justify="center" style={{backgroundImage:`url('./soul.jpg')`,backgroundColor:'transparent', backgroundSize:'cover'}} >
      
        <Col sm={8} lg={6} style={{backgroundColor:'rgba(255, 255, 255, 0.9)',padding:'5%',marginTop:'5%',marginBottom:'10%',borderRadius:'20px'}} >
     
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
               <img
          width={'100%'}
          height={200}
          style={{marginTop:'-30%'}}
          src='./HEAT-Final-copy-324x324.png'
          align="center"/>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                type="email"
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Row justify="space-between" align="middle">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={auth.loading}
                >
                  Log in
                </Button>
                <span>
                  Or <Link to="/register">register now!</Link>
                </span>
              </Row>
            </Form.Item>
            <span>
                 <Link to="/forgotpassword">Forgot your password?</Link>
           </span>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default NormalLoginPageReferal;
