const coreValues = [
    'Accomplishment',
    'Accountability',
    'Acknowledgement',
    'Adventure',
    'Authenticity',
    'Beauty',
    'Challenge',
    'Change',
    'Charity',
    'Cleanliness',
    'Collaboration',
    'Commitment',
    'Common Sense',
    'Communication',
    'Community',
    'Competence',
    'Competition',
    'Continuous Improvement',
    'Cooperation',
    'Coordination',
    'Courage',
    'Creativity',
    'Customer Satisfaction',
    'Decisiveness',
    'Democracy',
    'Discipline',
    'Discovery',
    'Diversity',
    'Efficacy',
    'Equality',
    'Excellence',
    'Expertise',
    'Fairness',
    'Faith',
    'Faithfulness',
    'Family',
    'Freedom',
    'Friendship',
    'Fun',
    'Genius',
    'Global View',
    'Good Will',
    'Goodness',
    'Gratitude',
    'Hard Work',
    'Harmony',
    'Health',
    'Honesty',
    'Honor',
    'Independence',
    'Individuality',
    'Inner Peace',
    'Innovation',
    'Intimacy',
    'Intensity',
    'Ingenuity',
    'Joy',
    'Justice',
    'Knowledge/Learning',
    'Leadership',
    'Love',
    'Loyalty',
    'Mastery',
    'Meaning',
    'Merit',
    'Methodical',
    'Money',
    'Openness',
    'Order',
    'Originality',
    'Patriotism',
    'Peace',
    'Perfection',
    'Personal Growth',
    'Pleasure',
    'Power',
    'Practicality',
    'Preservation',
    'Privacy',
    'Progress',
    'Prosperity',
    'Punctuality',
    'Quality',
    'Recycling',
    'Regularity',
    'Reliability',
    'Resourcefulness',
    'Respect for others',
    'Responsiveness',
    'Result-oriented',
    'Romance',
    'Rule of Law',
    'Safety',
    'Satisfying others',
    'Security',
    'Self-giving',
    'Self-reliance',
    'Service to others',
    'Seeing clearly',
    'Simplicity',
    'Skill',
    'Solving Problems',
    'Speed',
    'Spirituality',
    'Stability',
    'Standardization',
    'Status',
    'Strength',
    'Success',
    'Sustainability',
    'Systematization',
    'Teamwork',
    'Thoughtfulness',
    'Timeliness',
    'Tolerance',
    'Tradition',
    'Tranquility',
    'Trust',
    'Truth',
    'Unity',
    'Variety',
    'Wisdom',
    'Other'
];

export default coreValues;