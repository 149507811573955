import request from '../../shared/lib/api';

const getAllWheels = (from, to) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/wheel`,
    method: 'GET',
    params: {
      from,
      to,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const getLastWheel = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/wheel/last`,
    method: 'GET',

    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const createNewWheel = (wheelData) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/wheel`,
    method: 'POST',
    data: {
      ...wheelData,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const editWheel = (id, wheelData) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/wheel/${id}`,
    method: 'PATCH',
    data: {
      ...wheelData,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const deleteWheel = (id) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/wheel/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const WheelService = {
  createNewWheel,
  getLastWheel,
  getAllWheels,
  editWheel,
  deleteWheel,
};

export default WheelService;
