import { Button, Form, Modal, Radio, Space } from 'antd';
import { useState } from 'react';
import { FullWidthInputNumber as InputNumber } from '../../../../components/wrappers/inputs';
import MyLifeWheelPage from '../../my-life-wheel';
import { Link } from 'react-router-dom';

const Step2 = ({ form }) => {
  const [visible, setVisible] = useState(false);

  const onHeatChange = (data) => {
    const values = Object.values(data);
    const sum = values.reduce((a, b) => a + b, 0);

    const notZeros = Object.keys(data).filter((key) => data[key]);

    const result = `${sum}/${notZeros.length * 10}`;

    form.setFieldsValue({
      'a-2': result,
    });
  };

  return (
    <>
      <Form.Item
        name="a-2"
        label="a) Assess your total Human Potential Score (HPS) using H.E.A.T Life Target (Quantitative Assessment)"
        rules={[{ required: true }]}
        extra={
          <>
            <Button type="link" onClick={() => setVisible(true)}>
              Go to My H.E.A.T Life Target
            </Button>
          </>
        }
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="b-2"
        label="b) Have you completed your Core Value Assessment (CVA)? (Qualitative Assessment)"
        extra={
          <>
            <Button type="link">
              <Link to="/core-values">Go to My core Values</Link>
            </Button>
          </>
        }
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="c-2"
        label="c) Is your goal aligned with your #1 Core Value ?"
      
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Modal
        title="H.E.A.T Target Life"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width="90vw"
        okText="Submit"
        footer={false}
      >
        <MyLifeWheelPage
          onChange={onHeatChange}
          setVisible={setVisible}
          modal
        />
      </Modal>
    </>
  );
};

export default Step2;
