import { Button, Collapse, Table, message } from 'antd';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { useEffect, useState } from 'react';
import LifeMasteryService from '../../../../services/api/LifeMasteryService';
import { EyeFilled, DeleteOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const LifeMasteryHistoryTable = ({
  step,
  onEditClick,
  onChangeDate,
  onSetCurrentData,
  goalId,
  UpdateComponent 
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const groupedByDay = groupBy(data, (item) => {
    return moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss');
  });

  const groupedByDayKeys = Object.keys(groupedByDay);
  useEffect(() => {
    setLoading(true);

    LifeMasteryService.getAllLifeMasteriesWithGoalId(`${step + 1}`,goalId)
      .then((res) => res.data.data)
      .then((data) => {
        const withKeyData = data.lifeMastery.map((item) => ({
          ...item,
          key: item.id,
        }));

        const filteredArray = withKeyData.filter(e => e.softDelete != true)
        setData(filteredArray);
      })
      .finally(() => setLoading(false));
  }, [step, deleted,UpdateComponent]);

  const softDeleteData = (deleteData) => {
    setLoading(true);

    const newArray = deleteData.map((element, index) => {
      element.softDelete = true;
      return element;
    });

    // console.log("THIS IS DATA FOR DELETE " , newArray);
    LifeMasteryService.deleteLifeMastery({ lifeMastries: newArray })
      .then(() => {
        message.success('Saved successfully.');
      })
      .finally(() => {
        setLoading(false);
        // form.resetFields();
        // setDate(moment());
      });
  };

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: '50%',
      render: (_, key) => {
        return key;
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      width: '20%',
      render: (_, key) => {
        return moment(groupedByDay[key][0].updatedAt).format();
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, key) => {
        return (
          <Button
            type="primary"
            icon={<EyeFilled />}
            onClick={() => {
              // onEditClick(groupedByDay[key], key);
              onSetCurrentData(groupedByDay[key]);
              onChangeDate(moment(groupedByDay[key][0].createdAt));
            }}
          >
            View and edit
          </Button>
        );
      },
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      render: (_, key) => {
        return (
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              softDeleteData(groupedByDay[key]);
              setDeleted(e.target.value);

            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  return (
    <Collapse accordion>
      <Panel header="Goal History" key="1">
        <Table
          loading={loading}
          bordered
          dataSource={groupedByDayKeys}
          columns={columns}
        />
      </Panel>
    </Collapse>
  );
};

export default LifeMasteryHistoryTable;
