import { Button, Form, message, Radio, Space, Typography } from 'antd';
import { useState } from 'react';
import ContractService from '../../../../services/api/ContractService';
import MyDeclarationStatementModal from '../modals/my-declaration-statement-modal';

const { Text } = Typography;

const Step4 = () => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async (vals) => {

    try {
      setConfirmLoading(true);
      await ContractService.createNewContract('MY_DECLARATION_STATEMENTS');
      setConfirmLoading(false);
      setVisible(false);
      message.success('Contract added!');
    } catch (error) {}
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Form.Item
        name="a-4"
        label="Have you completed your Declaration Statement?"
        rules={[{ required: true }]}
        extra={
          <>
            <Text type="secondary">If not</Text>
            <Button type="link" onClick={() => setVisible(true)}>
              Go to My Declaration Statements
            </Button>
          </>
        }
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <MyDeclarationStatementModal
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        confirmLoading={confirmLoading}
      />
    </>
  );
};

export default Step4;
