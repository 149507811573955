import { Form, Space, Checkbox } from 'antd';

const Step5 = () => (
  <>
    <Form.Item name="a-5" label="Have you taken the following ACTIONS?">
      <Checkbox.Group>
        <Space direction="vertical">
          <Checkbox value="I have started a self-help program/course">I have started a self-help program/course</Checkbox>
          <Checkbox value=" I am regularly tracking my progress by entering the relevant data">
            I am regularly tracking my progress by entering the relevant data (Key Wellness Indicators and/or Key Impact Indicators)
          </Checkbox>
        </Space>
      </Checkbox.Group>
    </Form.Item>
  </>
);

export default Step5;
