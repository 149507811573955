import { Button, Col, Input, List, Row, Space, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/layout/page-title/PageTitle';
import ProductsService from '../../services/api/ProductsService';
import ProductCard from './components/ProductCard';
import CreateCouponModal from './modals/CreateCouponModal';
import useQuery from '../../hooks/useQuery';

const AllServicesPage = () => {
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [couponProduct, setCouponProduct] = useState(undefined);
  const { user } = useSelector((state) => state.auth);
  const query = useQuery();

  const categoryId = query.get('category');
  console.log("CAT                 : " , categoryId);

  const [state, setState] = useState({
    loading: false,
    data: undefined,
    error: null,
  });

  const getData = useCallback(async (searchTerm = '') => {
    setState({ ...state, loading: true });

    try {
     // const res = await ProductsService.getAllProductsByCategoryId(searchTerm);
      const res = await ProductsService.getAllProductsByCategoryId(categoryId); // 28 is weight loss

      res.data.data.products.sort((a, b) => parseInt(a.menu_order) - parseInt(b.menu_order));
      setState({ data: res.data, loading: false, error: null });
    } catch (error) {
      setState({ data: undefined, loading: false, error });
    }
  }, [categoryId]);

  useEffect(() => {
    getData(search);

    return () => {};
  }, [getData, search]);

  const { loading, data = { data: { products: [] } } } = state;
  const creditText = user && user.credit ? user.credit.toFixed(2) : '0.00';

  const onSearchHandler = (value) => {
    setSearchInputValue(value);
    setSearch(value);
    // _debounce((value) => setSearch(value), 1000);
  };

  return (
    <div>
      <PageTitle
        title="Services"
        extra={
          <Typography.Text style={{ fontSize: '20px' }}>
            Your credit: <Typography.Text strong>${creditText}</Typography.Text>
          </Typography.Text>
        }
      />
      <Row gutter={[16, 16]}>
        <Col span={22}>
          <Input
            onChange={(e) => {
              onSearchHandler(e.target.value);
            }}
            placeholder="Search products..."
            value={searchInputValue}
          />
        </Col>

        <Space align="center">
          <Col span={1}>
            <Button
              onClick={(e) => onSearchHandler('')}
              type="primary"
              size="default"
            >
              Reset
            </Button>
          </Col>
        </Space>
      </Row>

      <div style={{ marginBottom: '20px' }} />

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        pagination={{
          total: data.data.products.length,
          pageSize: 12,
        }}
        loading={loading}
        dataSource={data.data.products}
        renderItem={(item) => (
          <List.Item>
            <ProductCard
              {...item}
              onButtonClick={(product) => setCouponProduct(product)}
            />
          </List.Item>
        )}
      />

      <CreateCouponModal
        product={couponProduct}
        onCancel={() => setCouponProduct(undefined)}
      />
    </div>
  );
};

export default AllServicesPage;
