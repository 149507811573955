import { Result, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const Unauthorized = () => {
  const location = useLocation()
  const quer = location.state.query;
  const value = location.state.redirection.pathname+quer ;
  let urlRedirect ;
  if(value)
  {
     urlRedirect = 'https://app.heatwellbeing.com/login?redirect=' + value;
  }else{
     urlRedirect = 'https://app.heatwellbeing.com/login';
  }
  // console.log(value)
  // alert("AAAAAAAAAAAAAAAAAAAAA",value);
  useEffect(() => {
    window.location.replace(urlRedirect);
    // window.location.replace('https://app.heatwellbeing.com/login');
    // window.location.replace('https://localhost:3000/login');
  }, []);

  return (
    <Result icon={<Spin size="large" />} title="Unauthorized, please wait..." />
  );
};

export default Unauthorized;
