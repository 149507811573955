import request from '../../shared/lib/api';

const create = (values) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/coreValues`,
    method: 'POST',
    data: {
      ...values,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};
const getAllMyCoreValues = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/coreValues`,
    method: 'GET',
    params: {
      
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};
const update = (values) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/coreValues`,
    method: 'PATCH',
    data: {
      ...values,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const CoreValueService = {
  create,getAllMyCoreValues,update
};

export default CoreValueService;
