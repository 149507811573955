import { Checkbox, Form, Modal, Space } from 'antd';

const MyDeclarationStatementModal = ({
  visible,
  handleOk,
  handleCancel,
  confirmLoading,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="My Declaration Statements"
      visible={visible}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleOk(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="1"
          label="I hereby declare that (must check all boxes);"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox>
            I will embark on my personal transformation journey and commit to
            ongoing self-improvement
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="2"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox>
            I will use Dr. Vala's H.E.A.T System to empower myself and others
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="3"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox>
            I will commit to the ACTION ITEMS indicated within the specified
            timeline to achieve my desired Goal
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="4"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox>
            I vow to hold myself accountable to the following:
          </Checkbox>
        </Form.Item>

        <div style={{ paddingLeft: '20px' }}>
          <Space direction="vertical">
            <Form.Item
              name="5"
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Checkbox>My Coach/Mentor</Checkbox>
            </Form.Item>

            <Form.Item name="6" valuePropName="checked">
              <Checkbox>My Accountability Buddy</Checkbox>
            </Form.Item>

            <Form.Item
              name="7"
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Checkbox>H.E.A.T Community</Checkbox>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};
export default MyDeclarationStatementModal;
