const Step9 = () => (
  <>
    <ul>
      <li>Human Potential Score (HPS) at least 80</li>
      <li>GREEN at least 10</li>
      <li>Already be a Violet Band member</li>
      <li>Involved in a Global Project</li>
    </ul>
  </>
);

export default Step9;
