import { Form, Space, Checkbox } from 'antd';

const Step6 = () => (
  <>
    <Form.Item name="a-6" label="Have you taken the following ACTIONS to complete your achievement?">
      <Checkbox.Group>
        <Space direction="vertical">
          <Checkbox value="I completed the program/course"> I completed the program/course</Checkbox>
          <Checkbox value="I graduated and received my 'Certificate of Completion'">I graduated and received my 'Certificate of Completion'</Checkbox>
        </Space>
      </Checkbox.Group>
    </Form.Item>
  </>
);

export default Step6;
