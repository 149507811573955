import request from '../../shared/lib/api';

const getAllPoints = (data) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/points`,
    method: 'GET',

    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const PointService = {
  getAllPoints,
};

export default PointService;
