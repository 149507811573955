import { Radio, Checkbox, Input, Alert } from 'antd';
import { useMemo } from 'react';
import { FullWidthInputNumber, SizedTextArea } from '../wrappers/inputs';

const Question = (props) => {
  let { type, options, onChange, value, placeholder, hint } = props;

  if (!value && type === 'checkbox') {
    value = [];
  }

  const Wrapper = useMemo(() => {
    switch (type) {
      case 'radio':
        return Radio.Group;

      case 'checkbox':
        return Checkbox.Group;

      case 'text':
        return Input;

      case 'textarea':
        return SizedTextArea;

      case 'number':
        return FullWidthInputNumber;

      default:
        break;
    }
  }, [type]);

  const OptionWrapper = useMemo(() => {
    switch (type) {
      case 'radio':
        return Radio;

      case 'checkbox':
        return Checkbox;

      default:
        break;
    }
  }, [type]);

  const onChnageNormal = (e) => {
    if (e && typeof e === 'object' && e.target) {
      onChange(e.target.value);
    } else {
      onChange(e);
    }
  };

  let optionsComponent;
  if (type === 'radio' || type === 'checkbox') {
    if (Array.isArray(options)) {
      optionsComponent = options.map((opt) => (
        <OptionWrapper key={`option-${opt.value}`} value={opt.value}>
          {opt.title}
        </OptionWrapper>
      ));
    }
  }

  const wrapperProps = {
    onChange: onChnageNormal,
    value,
    placeholder,
  };

  const hintComponent = hint ? (
    <Alert
      style={{ marginTop: '10px' }}
      message={hint}
      type="warning"
      showIcon
    />
  ) : null;

  const OutputQuestion = optionsComponent ? (
    <>
      <Wrapper {...wrapperProps}>{optionsComponent}</Wrapper>
      {hintComponent}
    </>
  ) : (
    <>
      <Wrapper {...wrapperProps} />
      {hintComponent}
    </>
  );

  return OutputQuestion;
};

export default Question;
