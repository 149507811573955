import { Divider, Typography, Row } from 'antd';

const PageTitle = ({ title, extra }) => {
  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={3} style={{ margin: '0' }}>
          {title}
        </Typography.Title>
        {extra ? extra : null}
      </Row>
      <Divider style={{ marginBottom: '25px' }} />
    </>
  );
};

export default PageTitle;
