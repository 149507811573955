export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTO_LOGIN_FAIL = 'AUTO_LOGIN_FAIL';

export const REGISTER_FAIL_REDIRECT_REFERAL = 'REGISTER_FAIL_REDIRECT_REFERAL';
export const LOGIN_REQUEST_REFERAL = 'LOGIN_REQUEST_REFERAL';
export const LOGIN_SUCCESS_REFERAL = 'LOGIN_SUCCESS_REFERAL';
export const LOGIN_FAIL_REFERAL = 'LOGIN_FAIL_REFERAL';

export const LOGOUT = 'LOGOUT';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const GET_ALL_POSTS_REQUEST = 'GET_ALL_POSTS_REQUEST';
export const GET_ALL_POSTS_SUCCESS = 'GET_ALL_POSTS_SUCCESS';
export const GET_ALL_POSTS_FAIL = 'GET_ALL_POSTS_FAIL';

export const REFRESH_USER = 'REFRESH_USER';
