import request from '../../shared/lib/api';

const login = (wpUserId) =>
  request({
    url: `/auth/login`,
    method: 'POST',
    data: {
      wpUserId,
    },
  });
const loginWPEmail = (wpUserEmail) =>
  request({
    url: `/auth/loginWPEmail`,
    method: 'POST',
    data: {
      wpUserEmail,
    },
  });

const loginWithEmail = (userInput) =>
  request({
    url: `/auth/loginWithEmail`,
    method: 'POST',
    data: userInput,
  });

const signupWithEmail = (userInput) =>
  request({
    url: `/auth/signup`,
    method: 'POST',
    data: userInput,
  });
const signupWithReferal = (userInput) =>
  request({
    url: `/oscarapi/auth/signupreferal`,
    method: 'POST',
    data: userInput,
  });

const signinWithReferal = (userInput) =>
  request({
    url: `/oscarapi/auth/signinreferal`,
    method: 'POST',
    data: userInput,
  });

  
const forgotPassword = (data) =>
request({
  url: `/auth/forgotpassword`,
  method: 'POST',
  data,
});

const resetPassword = (data) =>
request({
  url: `/auth/resetpassword`,
  method: 'POST',
  data,
});


const AuthService = {
  login,
  loginWithEmail,
  loginWPEmail,
  signupWithEmail,
  forgotPassword,
  resetPassword,
  signupWithReferal,
  signinWithReferal
};

export default AuthService;
