import { Select } from 'antd';
import { useEffect, useState } from 'react';
import convert from 'convert-units';

const { Option } = Select;

const measures = {
  mass: ['lb', 'kg'],
  length: ['cm', 'in'],
};

const UnitConverter = ({ measure, value = undefined, onChange = () => {} }) => {
  const defaultUnit =
    measure && Array.isArray(measures[measure]) && measures[measure].length
      ? measures[measure][0]
      : '';

  const [selectedUnit, setSelectedUnit] = useState(defaultUnit);

  const onUnitSwitch = (newUnit) => {
    const newValue = convert(value).from(selectedUnit).to(newUnit);
    setSelectedUnit(newUnit);

    onChange(newValue, newUnit);
  };

  useEffect(() => {
    onChange(value, selectedUnit);
  }, [value, onChange, selectedUnit]);

  if (!measure) {
    return null;
  }

  const radioButtons = measures[measure].map((unit) => (
    <Option key={`radio-${unit}`} value={unit}>
      {unit}
    </Option>
  ));

  return (
    <div>
      <Select
        value={selectedUnit}
        onChange={onUnitSwitch}
        style={{ width: '100%' }}
      >
        {radioButtons}
      </Select>
    </div>
  );
};

export default UnitConverter;
