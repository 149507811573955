import {
  Button,
  Steps,
  Form,
  Space,
  Typography,
  message,
  DatePicker,
  Spin,Tag
} from 'antd';

import { LeftOutlined,EditOutlined} from '@ant-design/icons';
import { useMemo, useState, useEffect } from 'react';
import { Step1, Step2, Step3 } from './steps';
import MyCoreValuesHistoryTable from './components/MyCoreValuesHistoryTable';

import moment from 'moment';

import './styles.less';

import CoreValueService from '../../../services/api/CoreValueService';
import PageTitle from '../../../components/layout/page-title/PageTitle';

const { Step } = Steps;

const CoreValuesPage = () => {
  const steps = useMemo(
    () => [
      {
        title: 'STEP 1',
        description: '',
        form: Step1,
      },
      {
        title: 'STEP 2',
        description: '',
        form: Step2,
      },
      {
        title: 'STEP 3',
        description: '',
        form: Step3,
      },
    ],
    [],
  );

  const [form] = Form.useForm();

  const [currentStep, setCurrentStep] = useState(0);
  const [values, setValues] = useState({});
  const [date, setDate] = useState(moment());
  const [fetchedData, setFetchedData] = useState();
  const [loading, setLoading] = useState(false);
  const [isModifying, setModifying] = useState(false);
  const [modifiedData, setModifiedData] = useState({});
  const [updateComponent,setUpdateComponent] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    CoreValueService.getAllMyCoreValues()
      .then((res) => res.data.data)
      .then((data) => {
        const dataSort = data.data.sort(
          (a, b) => a[0].createdAt - b[0].createdAt,
        );
        const formateDate = dataSort.map((item) => ({
          coreValues: item[0].coreValues,
          topCoreValue: item[0].topCoreValue,
          topFiveCoreValues: item[0].topFiveCoreValues,
          formateddate: moment(item[0].createdAt).format('YYYY-MM-DD hh:mm:ss'),
          createdAt: item[0].createdAt,
          userId: item[0].userId,
          key: item[0].id,
        }));

        /// current date set form
        setFetchedData(formateDate);
        let dateFound = false;
        formateDate.forEach((fdata) => {
          if (!dateFound) {
            const exist = moment(moment(date).startOf('day')).isSame(
              moment(fdata.createdAt).startOf('day'),
            );
            if (exist) {
              setFormData(fdata);
            }
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [updateComponent]);

  const isInLastStep = useMemo(
    () => currentStep === steps.length - 1,
    [currentStep, steps],
  );

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setValues({ ...values, ...form.getFieldsValue() });
    setCurrentStep(currentStep - 1);
  };

  const setFormData = (data) => {
    form.resetFields();
    form.setFieldsValue(data);
    setDate(moment(data.createdAt).startOf('day'));
    setModifying(true);
    setModifiedData(data);
  };
  const loadingComponent = loading ? (
    <Spin size="large" style={{ display: 'flex', justifyContent: 'center' }} />
  ) : null;

  const submitToApi = (vals) => {
    if (isModifying)
    {
      const withKeyData = ({
        ...vals,
        createdAt: modifiedData.createdAt,
        id: modifiedData.key,
        userId : modifiedData.userId
      });


      CoreValueService.update(withKeyData)
      .then(() => {
        message.success('Data updated successfully.');
      })
      .catch((error) => {
        message.error(error.message);
      });
    }else{
      const withKeyData = ({
        ...vals,
        createdAt: date,
      });

      CoreValueService.create(withKeyData)
      .then(() => {
        message.success('Data saved successfully.');
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

    }


  const onFinish = (vals) => {
    setValues({ ...values, ...vals });

    if (!isInLastStep) {
      next();
    } else {
      setUpdateComponent({});

      submitToApi({ ...values, ...vals });
    }
  };
  const dateIsUpdated = (date) => {
    setDate(date);
    let dateFound = false;
    let exist = false;
    fetchedData.forEach((fdata) => {
      if (!dateFound && !exist) {
        exist = moment(moment(date).startOf('day')).isSame(
          moment(fdata.createdAt).startOf('day'),
        );
        if (exist) {
          setFormData(fdata);
        }
      }
    });
    if (!exist) {
      form.resetFields();
      setCurrentStep(0);
      setModifying(false);
      setModifiedData({});
    }
  };

  const resetToCurrent = ()=>{
    dateIsUpdated(moment());
  }

  const preButton = (
    <Button
      type="default"
      onClick={() => prev()}
      icon={<LeftOutlined />}
      disabled={!(currentStep > 0)}
    />
  );
  const editingAlert = (isModifying ? <Tag icon={<EditOutlined />} color="orange" style={{marginLeft: '2%'}}>
  You are currently editing previous data
  </Tag> : null
  )

  const resetBtn = (
    <Button type="secondary" onClick={resetToCurrent}>
      {/* {resetToCurrent ? 'Submit' : 'Next'} */}
      Reset
    </Button>
  );

  const submitBtn = (
    <Button type="primary" htmlType="submit">
      {isInLastStep ? 'Submit' : 'Next'}
    </Button>
  );

  const StepFormItem = steps[currentStep]?.form;

  return (
    <>
      <PageTitle title="My Core Values" />
      {loadingComponent}
      {loading ? null : (
        <DatePicker
          value={date}
          style={{ marginBottom: '5%' }}
          dateRender={(current) => {
            const style = {};

            return (
              <div className="ant-calendar-date" style={style}>
                {current.date()}
              </div>
            );
          }}
          defaultValue={date}
          onChange={dateIsUpdated}
        />
        
      )}
      {loading ? null : resetBtn}
      {loading ? null :
        editingAlert
      }
   

      {loading ? null : (
        <Steps current={currentStep} progressDot>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      )}

      {loading ? null : (
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{ 'core-values': [] }}
        >
          <div className="steps-content">
            <Typography.Text className="steps-description" type="secondary">
              {steps[currentStep].description}
            </Typography.Text>
            <div style={{ paddingTop: '20px' }}>
              {<StepFormItem values={values} />}
            </div>
          </div>

          <div className="steps-action">
            <Space>
              {preButton}
              {submitBtn}
            </Space>
          </div>
          <MyCoreValuesHistoryTable
            // step={currentStep}
            onEditClick={setFormData}
            wholedata={fetchedData}
          />
        </Form>
      )}
    </>
  );
};

export default CoreValuesPage;
