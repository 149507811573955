import { useEffect, useState, useCallback } from 'react';

const useApi = (apiService, initialState = undefined, ...params) => {
  const [state, setState] = useState({
    loading: false,
    data: initialState,
    error: null,
  });

  const callService = useCallback(async () => {
    setState({ ...state, loading: true });

    try {
      const res = await apiService(...params);
      setState({ data: res.data, loading: false, error: null });
    } catch (error) {
      setState({ data: initialState, loading: false, error });
    }
  }, [apiService, initialState, state, params]);

  useEffect(() => {
    callService();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: state.loading,
    data: state.data,
    error: state.error,
  };
};

export default useApi;
