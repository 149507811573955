import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  BarcodeOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Checkbox, Typography } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { registerUserWithEmail } from '../../../store/actions/authActions';
// import logo from "../../../../public/HEAT-Final-copy-324x324.png"

const RegisterPage = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (auth.isLoggedIn) {
      const redirectTo = auth?.redirectTo ? auth?.redirectTo : '/';
      history.push(location.state?.form.pathname || redirectTo);
    }
  }, [auth, location, history]);

  const onFinish = (values) => {
    dispatch(registerUserWithEmail(values));
  };

  return (
    <>
      <Row justify="center">
        <Col
          sm={8}
          lg={12}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: '5%',
            borderRadius: '20px',
          }}
        >
          <img width={200} src="./HEAT-Final-copy-324x324.png" style={{margin:"auto",display:"block"}} />
          <Form name="normal_login" layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                { required: true, message: 'Please input your First Name!' },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="First Name"
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                { required: true, message: 'Please input your Last Name!' },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Last Name"
              />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              rules={[
                { required: true, message: 'Please input your Username!' },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="postalCode"
              label="PostalCode"
              rules={[
                { required: true, message: 'Please input your postalcode!' },
              ]}
            >
              <Input
                prefix={<BarcodeOutlined className="site-form-item-icon" />}
                placeholder="postalcode"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
              label="Email"
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                type="email"
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
              label="Password"
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {/* <Input.TextArea onChange={(e)=> {}} defaultValue={""}></Input.TextArea> */}
            <Typography>

            By clicking  Sign up, you agree to our  <Typography.Link href="https://heatwellbeing.com/terms-of-use/" target="_blank">
            Terms and Conditions </Typography.Link>.
            </Typography> 
            <Form.Item
              name="allowTerm"
              valuePropName="checked"
              rules={[
                {
                  validator: async (_, checked) => {
                    if (!checked) {
                      return Promise.reject(
                        new Error(
                          'you must accept Terms and Conditions',
                        ),
                      );
                    }
                  },
                },
              ]}
            >
              <Checkbox>I accept</Checkbox>
            </Form.Item>

            <Form.Item>
              <Row justify="space-between" align="middle">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={auth.loading}
                >
                  Sign up
                </Button>
                <span>
                  Or <Link to="/normal-login">Login now!</Link>
                </span>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RegisterPage;
