import { Form, Select } from 'antd';

const { Option } = Select;

const Step2 = ({ values }) => (
  <>
    <Form.Item
      name="topFiveCoreValues"
      label="Rank the top 3-5 Core Values in order of importance to you at this stage of your life."
      rules={[{ required: true }, { type: 'array', min: 3 }, { type: 'array', max: 5 }]}
    >
      <Select
        mode="multiple"
      >
        {values['coreValues'] ? values['coreValues'].map((value) => <Option key={value} value={value}>{value}</Option>) : null}
      </Select>
    </Form.Item>
  </>
);

export default Step2;
