const Step7 = () => (
  <>
    <ul>
      <li>Human Potential Score (HPS) at least 70</li>
      <li>GREEN at least 8</li>
      <li>Already be a Blue Band member</li>
    </ul>
  </>
);

export default Step7;
