import { Input, InputNumber, Select, Spin, DatePicker } from 'antd';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { GithubPicker } from 'react-color';
import { colorHexToName, colorNameToHex } from '../../../utils/mapper';
import './styles.less';
import {BarChartOutlined} from '@ant-design/icons';

const { Option, OptGroup } = Select;

export const FullWidthInputNumber = (props) => {
  return <InputNumber {...props} style={{ width: '100%' }} />;
};

export const SizedTextArea = (props) => {
  return <Input.TextArea {...props} autoSize={{ minRows: 3, maxRows: 5 }} />;
};

export const ColorPicker = (props) => {
  const { value, onChange, colorMap } = props;

  const colors = useMemo(() => colorMap.map((color) => color.hex), [colorMap]);

  const onColorChange = (colorDetail) => {
    onChange(colorHexToName(colorMap, colorDetail.hex));
  };

  return (
    <GithubPicker
      {...props}
      colors={colors}
      color={colorNameToHex(colorMap, value)}
      onChange={onColorChange}
    />
  );
};

export const DebounceSelect = ({
  fetchOptions,
  debounceTimeout = 800,
  withGroup = false,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  let optionComponents = [];
  if (withGroup) {
    const grouped = groupBy(options, (opt) => opt.groupName);
    optionComponents = Object.keys(grouped).map((key) => {
      const options = grouped[key].map((opt) => {
        const { label, value } = opt;

        return (
          <Option value={value} key={value}>
            {label}
          </Option>
        );
      });

      return (
        <OptGroup key={key} label={key}>
          {options}
        </OptGroup>
      );
    });
  } else {
    optionComponents = options.map((opt) => {
      const { label, value } = opt;

      return (
        <Option value={value} key={value}>
          {label}
        </Option>
      );
    });
  }

  return (
    <Select
      showSearch={true}
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="medium" /> : null}
      {...props}
    >
      {optionComponents}
    </Select>
  );
};

export const IsoStringDatePicker = (props) => {
  const handleChange = (time) => {
    if (!props.onChange) return;

    props.onChange(moment(time).toISOString());
  };

  const mappedValue = props && props.value ? moment(props.value) : undefined;

  return <DatePicker {...props} onChange={handleChange} value={mappedValue} />;
};
