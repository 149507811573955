import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Typography ,message, Spin} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { loginUserWithEmail } from '../../../store/actions/authActions';
import AuthService from '../../../services/api/ApiAuthService';
import useQuery from '../../../hooks/useQuery';


const ResetPassword = () => {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const query = useQuery();

  useEffect(() => {
    if (auth.isLoggedIn) {
      const redirectTo = auth?.redirectTo ? auth?.redirectTo : '/';
      history.push(location.state?.form.pathname || redirectTo);
    }
  }, [auth, location, history]);

  const loadingComponent = loading ? (
    <>
     <img
              width={'100%'}
              height={200}
              style={{ marginTop: '-30%' }}
              src="/HEAT-Final-copy-324x324.png"
              align="center"
            />
    <Spin size="large" style={{ display: 'flex', justifyContent: 'center' }} />
    </>
  ) : null;

  const onFinish = (values) => {
    setLoading(true);
    const token  = query.get('token');
    const userid  = query.get('id');

    values = {
      ...values,
      token,
      userid
    };
     AuthService.resetPassword(values)
     .then(()=>{
      message.success('Your password has been successfully changed');
      history.push('/login');
      setLoading(false);
     }).catch((error)=>{
      const errMessage = error?.response?.data?.message;
      message.error(errMessage);
      history.push('/login');
     })
    
  };

  return (
    <>
      <Row
        justify="center"
        style={{
          backgroundImage: `url('/soul.jpg')`,
          backgroundColor: 'transparent',
          backgroundSize: 'cover',
        }}
      >
        <Col
          sm={8}
          lg={6}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '5%',
            marginTop: '5%',
            marginBottom: '10%',
            borderRadius: '20px',
          }}
        >
          {loadingComponent }
          {loading ? null : 
          <Form
            name="normal_login"
            className="login-form"
            layout='vertical'
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <img
              width={'100%'}
              height={200}
              style={{ marginTop: '-30%' }}
              src="/HEAT-Final-copy-324x324.png"
              align="center"
            />
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                { min: 5, message: 'Password must be minimum 5 characters.' },

              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                { min: 5, message: 'Password must be minimum 5 characters.' },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!',
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Row justify="space-between" align="middle">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={auth.loading}
                >
                  Submit
                </Button>
                <span>
                  <Link to="/login">login</Link>
                </span>
              </Row>
            </Form.Item>
          </Form>
          }
        </Col>
      </Row>
      
    </>
  );
};

export default ResetPassword;
