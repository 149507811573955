import { Form, Input, Radio, Space, Typography } from 'antd';

const { Text } = Typography;

const Step7 = () => (
  <>
    <Form.Item
      name="a-7"
      label="a) What did you learn from this experience?"
    >
      <Input.TextArea rows={4} />
    </Form.Item>

    <Form.Item
      name="b-7"
      label="b) How will you apply what you learned in your future journey?"
    >
      <Input.TextArea rows={4} />
    </Form.Item>

    <Form.Item
      name="e-7"
      label="e) Congratulations for achieving another milestone in your life. Are you ready to aim for your next Rank (Goal) in life?"
      extra={
        <Text type="secondary">If yes, start a new cycle from STEP 1. If no, you can continue the journey to your Ultimate Self when you are ready.</Text>
      }
    >
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="Yes">Yes</Radio>
          <Radio value="No">No</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  </>
);

export default Step7;
