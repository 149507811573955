import convert from 'convert-units';

// const measures = {
//   mass: ['lb', 'kg'],
//   length: ['ft', 'in', 'cm', 'm'],
// };

export const toSaveUnits = {
  mass: 'kg',
  length: 'cm',
};

export const toShowUnits = {
  mass: 'lb',
  length: 'cm',
};

export const convertToShow = (value, measure) => {
  return convert(value).from(toSaveUnits[measure]).to(toShowUnits[measure]);
};

export const convertToSaveUnit = (value, unit, measure) => {
  return convert(value).from(unit).to(toSaveUnits[measure]);
};
