import React from 'react';
import careerImage from '../../../../assets/career.png';
import educationImage from '../../../../assets/education.png';
import environmentImage from '../../../../assets/environment.png';
import moneyImage from '../../../../assets/money.png';
import peaceImage from '../../../../assets/peace.png';
import soulImage from '../../../../assets/soul.png';
import bodyImage from '../../../../assets/body.png';
import mindImage from '../../../../assets/mind.png';
import loveImage from '../../../../assets/love.png';
import sexualityImage from '../../../../assets/sexuality.png';

const WheelSVG = ({ modal, setModal }) => {
  // const [modal, setModal] = useState({
  //   show: false,
  //   image: undefined,
  // });

  return (
    <>
      <svg
        id="wellnessWheel"
        className="wellness-wheel"
        data-name="wellnessWheel"
        viewBox="0 0 680 450"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="wheelChart"
          transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)"
        >
          <circle className="cls-1" cx="860.58" cy="429.95" r="427.9" />
          <circle className="cls-2" cx="860.58" cy="429.95" r="387.8" />
          <circle className="cls-3" cx="860.58" cy="429.95" r="347.7" />
          <circle className="cls-4" cx="860.58" cy="429.95" r="307.6" />
          <circle className="cls-5" cx="860.58" cy="429.95" r="267.5" />
          <circle className="cls-6" cx="860.58" cy="429.95" r="227.4" />
          <circle className="cls-7" cx="860.58" cy="429.95" r="187.3" />
          <circle className="cls-8" cx="860.58" cy="429.95" r="147.2" />
          <circle className="cls-9" cx="860.58" cy="429.95" r="107.1" />
          <circle className="cls-10" cx="860.58" cy="429.95" r="67" />
        </g>
        <g transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)">
          <title>Circle Number</title>
          <g>
            <path
              d="M1187,685.38l-1.93,2.45,8.63,6.8,1.47-1.86.5.39a7.51,7.51,0,0,0-.92,2.52l-.46.58-9.79-7.71-1.79,2.27-.63-.51,4.29-5.43Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1155.4,659.31c.79,6.16,1.37,9.4,3.18,10.83a2.68,2.68,0,0,0,4.1-.45,4.13,4.13,0,0,0,.56-3.5l.81-.12a5,5,0,0,1-.8,4.19,3.45,3.45,0,0,1-5.23.63c-2.07-1.64-2.57-5.09-3.35-10.4-.42.61-.86,1.24-1.31,1.81l-2.8,3.55-.64-.51,5-6.38Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1124.41,634.53l.17.82a4.71,4.71,0,0,0-3.61,1.83,2.88,2.88,0,0,0,.38,4.25c1.43,1.12,3.23.86,5.27-1.73l.64.5c-1.9,2.4-1.55,4-.3,5a2.42,2.42,0,0,0,3.6-.57,4.27,4.27,0,0,0,.74-3.28l.83,0a5.07,5.07,0,0,1-1,3.84c-1.26,1.6-3.16,2.11-4.75.85a3.35,3.35,0,0,1-1-4l-.06,0a3.55,3.55,0,0,1-4.58.23,3.74,3.74,0,0,1-.43-5.51A5.43,5.43,0,0,1,1124.41,634.53Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1095.09,618.83c.49.38,1.29,1.08,1.75,1.49l.05-.07c-.24-.61-.39-1.2-.63-1.87l-1.9-6.46-4.85,6.15-.62-.49,5.54-7,.43.34,3.07,10.27-.51.64L1087,613.58l.55-.7Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1060.44,584l.17.81a4.64,4.64,0,0,0-3.51,1.85,3.35,3.35,0,0,0,.76,4.79,3.09,3.09,0,0,0,4.69-.42,4.09,4.09,0,0,0,.83-2.48l.71-.26,4.57,4.22-3.79,4.81-.64-.51,3.27-4.15-3.49-3.3a4.32,4.32,0,0,1-.92,2.28c-1.39,1.77-3.52,2.39-5.79.59a4.21,4.21,0,0,1-.85-6.06A5.48,5.48,0,0,1,1060.44,584Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1031.64,562c4.36,3.44,4.45,6.47,2.87,8.48a3.63,3.63,0,0,1-2.75,1.4l-.14-.82a2.91,2.91,0,0,0,2.26-1.09c1.31-1.67,1.1-4.16-2.81-7.24-2.55-2-5-2.55-6.43-.7-1,1.2-.51,3,1,4.18s3.15,1.4,4.39-.19a4.3,4.3,0,0,0,.37-3.92l.78.59a4.56,4.56,0,0,1-.61,3.9c-1.43,1.82-3.4,2-5.5.35a3.86,3.86,0,0,1-1-5.42C1025.68,559.4,1028.48,559.47,1031.64,562Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1000.49,546.07l4.33-5.48.65.5-5.05,6.4-.44-.34c-1.33-5.1-3.46-7.77-7.39-11.15l.61-.78C997.27,538.7,999.21,541.66,1000.49,546.07Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M965.79,509.7a4.5,4.5,0,0,1,1.31,4.39l.06,0a3.73,3.73,0,0,1,3.56.4,3.27,3.27,0,0,1,.4,4.73,3.32,3.32,0,0,1-4.94.67,4.78,4.78,0,0,1-1.48-3.57l-.07,0c-1.25.46-2.68.83-4.08-.27s-1.73-3.42-.23-5.31S964.15,508.4,965.79,509.7Zm-4.65,5.63c1.59,1.26,3.39.13,5.3-1a4,4,0,0,0-1.18-3.92,3.06,3.06,0,0,0-4.34.79A2.81,2.81,0,0,0,961.14,515.33Zm5.57,3.9a2.59,2.59,0,0,0,3.82-.44,2.45,2.45,0,0,0-.37-3.54c-1.48-1.16-3.18-.22-4.79.71A4,4,0,0,0,966.71,519.23Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M931.76,483.27l.14.82a2.92,2.92,0,0,0-2.26,1.09c-1.31,1.67-1.1,4.16,2.82,7.24,2.54,2,5,2.55,6.42.7,1-1.2.51-3-1-4.18s-3.14-1.4-4.39.19a4.33,4.33,0,0,0-.37,3.92l-.77-.58a4.54,4.54,0,0,1,.6-3.91c1.43-1.81,3.41-2,5.51-.35a3.87,3.87,0,0,1,1,5.42c-1.64,2.08-4.43,2-7.59-.48-4.37-3.44-4.46-6.47-2.87-8.48A3.55,3.55,0,0,1,931.76,483.27Z"
              transform="translate(-7.07 -6.21)"
            />
            <g>
              <path
                d="M902.33,454.45l-1.94,2.45,8.63,6.81,1.47-1.87.5.4a7.44,7.44,0,0,0-.92,2.52l-.46.58-9.78-7.72L898,459.89l-.64-.5,4.29-5.44Z"
                transform="translate(-7.07 -6.21)"
              />
              <path
                d="M901.24,465.31c3.46,2.73,4.48,5.36,2.91,7.35s-4.36,1.6-7.82-1.13-4.56-5.41-3-7.4S897.77,462.58,901.24,465.31Zm-4.34,5.49c3.18,2.51,5.49,2.81,6.62,1.37s.31-3.62-2.86-6.13-5.55-2.86-6.69-1.41S893.73,468.3,896.9,470.8Z"
                transform="translate(-7.07 -6.21)"
              />
            </g>
          </g>
          <g>
            <path
              d="M547.1,190.71l1.94-2.45-8.63-6.81-1.48,1.87-.49-.39a7.51,7.51,0,0,0,.92-2.52l.46-.59,9.78,7.72,1.79-2.27.64.5-4.29,5.44Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M578.71,216.77c-.79-6.16-1.37-9.39-3.18-10.82a2.66,2.66,0,0,0-4.09.44,4.13,4.13,0,0,0-.56,3.5l-.82.13a5,5,0,0,1,.8-4.19,3.46,3.46,0,0,1,5.24-.63c2.07,1.63,2.57,5.09,3.34,10.4.43-.61.87-1.24,1.32-1.81l2.8-3.55.64.51-5,6.38Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M609.71,241.55l-.17-.81a4.68,4.68,0,0,0,3.6-1.83,2.88,2.88,0,0,0-.37-4.25c-1.43-1.13-3.23-.87-5.28,1.72l-.63-.5c1.89-2.4,1.55-4,.3-5a2.42,2.42,0,0,0-3.6.57,4.22,4.22,0,0,0-.74,3.28l-.83,0a5,5,0,0,1,1-3.83c1.27-1.61,3.17-2.11,4.76-.85a3.33,3.33,0,0,1,1,4l.07,0a3.53,3.53,0,0,1,4.58-.23,3.74,3.74,0,0,1,.43,5.51A5.42,5.42,0,0,1,609.71,241.55Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M639,257.25c-.48-.37-1.29-1.07-1.74-1.48l0,.06c.23.61.39,1.21.63,1.88l1.9,6.45,4.85-6.15.62.5-5.54,7-.43-.34-3.07-10.26.51-.65,10.42,8.22-.56.7Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M673.67,292.1l-.17-.81a4.68,4.68,0,0,0,3.52-1.84,3.37,3.37,0,0,0-.76-4.8,3.09,3.09,0,0,0-4.69.43,4,4,0,0,0-.83,2.48l-.71.25-4.58-4.22,3.8-4.8.64.5-3.28,4.16,3.5,3.3a4.3,4.3,0,0,1,.92-2.29c1.39-1.76,3.51-2.38,5.78-.59a4.22,4.22,0,0,1,.86,6.07A5.53,5.53,0,0,1,673.67,292.1Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M702.48,314.12c-4.36-3.44-4.46-6.46-2.87-8.47a3.61,3.61,0,0,1,2.74-1.41l.15.83a2.84,2.84,0,0,0-2.26,1.09c-1.32,1.66-1.1,4.15,2.81,7.24,2.55,2,5,2.55,6.42.69.95-1.2.52-3-1-4.18s-3.15-1.39-4.4.19a4.37,4.37,0,0,0-.37,3.93l-.77-.59a4.59,4.59,0,0,1,.6-3.9c1.44-1.82,3.41-2,5.51-.35a3.86,3.86,0,0,1,1,5.42C708.44,316.68,705.64,316.62,702.48,314.12Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M733.62,330l-4.33,5.49-.64-.51,5.05-6.4.43.34c1.33,5.1,3.46,7.78,7.4,11.15l-.61.78C736.85,337.38,734.9,334.42,733.62,330Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M768.33,366.39A4.51,4.51,0,0,1,767,362l-.06-.05a3.72,3.72,0,0,1-3.56-.39,3.28,3.28,0,0,1-.41-4.74,3.34,3.34,0,0,1,5-.67,4.8,4.8,0,0,1,1.48,3.58l.06,0c1.26-.46,2.69-.84,4.09.27s1.72,3.41.22,5.31S770,367.68,768.33,366.39Zm-.92-9.54a2.61,2.61,0,0,0-3.82.44,2.43,2.43,0,0,0,.37,3.54c1.48,1.17,3.18.23,4.79-.7A4,4,0,0,0,767.41,356.85Zm5.57,3.91c-1.6-1.27-3.39-.14-5.31,1a4.05,4.05,0,0,0,1.18,3.92,3.07,3.07,0,0,0,4.35-.79A2.81,2.81,0,0,0,773,360.76Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M802.36,392.82l-.14-.83a2.84,2.84,0,0,0,2.25-1.09c1.32-1.66,1.1-4.15-2.81-7.24-2.54-2-5-2.55-6.42-.7-1,1.21-.52,3,1,4.19s3.15,1.39,4.4-.19A4.34,4.34,0,0,0,801,383l.77.59a4.52,4.52,0,0,1-.6,3.9c-1.44,1.82-3.41,2-5.51.35a3.86,3.86,0,0,1-1-5.42c1.63-2.07,4.43-2,7.59.49,4.36,3.44,4.46,6.46,2.87,8.47A3.59,3.59,0,0,1,802.36,392.82Z"
              transform="translate(-7.07 -6.21)"
            />
            <g>
              <path
                d="M831.79,421.63l1.93-2.45-8.62-6.81-1.48,1.87-.49-.39a7.74,7.74,0,0,0,.92-2.52l.46-.59,9.78,7.72,1.79-2.27.64.5-4.29,5.44Z"
                transform="translate(-7.07 -6.21)"
              />
              <path
                d="M832.88,410.77c-3.46-2.73-4.48-5.36-2.91-7.34s4.36-1.61,7.82,1.12,4.55,5.42,3,7.4S836.34,413.51,832.88,410.77Zm4.33-5.49c-3.17-2.5-5.48-2.8-6.62-1.36s-.31,3.62,2.87,6.12,5.55,2.86,6.69,1.42S840.39,407.79,837.21,405.28Z"
                transform="translate(-7.07 -6.21)"
              />
            </g>
          </g>
          <g>
            <path
              d="M1118.45,117.88l2.45,1.94,6.81-8.63-1.87-1.48.39-.49a7.51,7.51,0,0,0,2.52.92l.59.46-7.72,9.78,2.27,1.79-.5.64-5.44-4.29Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1092.39,149.49c6.16-.79,9.4-1.37,10.82-3.18a2.66,2.66,0,0,0-.44-4.09,4.13,4.13,0,0,0-3.5-.56l-.13-.82a5,5,0,0,1,4.19.8,3.46,3.46,0,0,1,.63,5.24c-1.63,2.07-5.09,2.57-10.4,3.34.61.43,1.24.87,1.81,1.32l3.55,2.8-.51.64-6.38-5Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1067.61,180.49l.81-.17a4.68,4.68,0,0,0,1.83,3.6,2.88,2.88,0,0,0,4.25-.37c1.13-1.43.87-3.23-1.72-5.28l.5-.63c2.4,1.89,4,1.55,5,.3a2.42,2.42,0,0,0-.57-3.6,4.22,4.22,0,0,0-3.28-.74l0-.83a5,5,0,0,1,3.83.95c1.61,1.27,2.11,3.17.85,4.76a3.33,3.33,0,0,1-4,1l0,.07a3.53,3.53,0,0,1,.23,4.58,3.74,3.74,0,0,1-5.51.43A5.42,5.42,0,0,1,1067.61,180.49Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1051.91,209.8c.37-.48,1.07-1.29,1.48-1.74l-.06-.05c-.61.23-1.21.39-1.87.63l-6.46,1.9,6.15,4.85-.49.62-7-5.54.34-.43,10.26-3.07.65.51-8.22,10.42-.7-.56Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M1017.06,244.45l.81-.17a4.68,4.68,0,0,0,1.84,3.52,3.37,3.37,0,0,0,4.8-.76,3.09,3.09,0,0,0-.43-4.69,4,4,0,0,0-2.48-.83l-.25-.71,4.22-4.58,4.8,3.8-.5.64-4.16-3.28-3.3,3.5a4.3,4.3,0,0,1,2.29.92c1.76,1.39,2.38,3.51.59,5.78a4.22,4.22,0,0,1-6.07.86A5.53,5.53,0,0,1,1017.06,244.45Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M995,273.26c3.44-4.36,6.46-4.46,8.47-2.87a3.61,3.61,0,0,1,1.41,2.74l-.83.15A2.84,2.84,0,0,0,1003,271c-1.66-1.32-4.15-1.1-7.24,2.81-2,2.55-2.55,5-.69,6.42,1.2.95,3,.52,4.18-1s1.39-3.15-.19-4.4a4.37,4.37,0,0,0-3.93-.37l.59-.77a4.59,4.59,0,0,1,3.9.6c1.82,1.44,2,3.41.35,5.51a3.86,3.86,0,0,1-5.42,1C992.48,279.22,992.54,276.42,995,273.26Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M979.15,304.4l-5.49-4.33.51-.64,6.4,5.05-.34.43c-5.1,1.33-7.78,3.46-11.15,7.4l-.78-.61C971.78,307.63,974.74,305.68,979.15,304.4Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M942.77,339.11a4.51,4.51,0,0,1,4.4-1.31l.05-.06a3.7,3.7,0,0,1,.4-3.56,3.27,3.27,0,0,1,4.73-.41,3.34,3.34,0,0,1,.67,5,4.79,4.79,0,0,1-3.57,1.48l-.06.06c.46,1.26.84,2.69-.27,4.09s-3.41,1.72-5.31.22S941.48,340.74,942.77,339.11Zm5.63,4.65c1.27-1.6.14-3.39-1-5.31a4.05,4.05,0,0,0-3.92,1.18,3.08,3.08,0,0,0,.79,4.35A2.81,2.81,0,0,0,948.4,343.76Zm3.91-5.57a2.61,2.61,0,0,0-.44-3.82,2.43,2.43,0,0,0-3.54.37c-1.17,1.48-.23,3.18.7,4.79A4,4,0,0,0,952.31,338.19Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M916.34,373.14l.83-.14a2.84,2.84,0,0,0,1.09,2.25c1.66,1.32,4.15,1.1,7.24-2.81,2-2.54,2.55-5,.7-6.42-1.21-.95-3-.52-4.19,1s-1.39,3.15.19,4.4a4.35,4.35,0,0,0,3.93.37l-.59.77a4.52,4.52,0,0,1-3.9-.6c-1.82-1.44-2-3.41-.35-5.51a3.86,3.86,0,0,1,5.42-1c2.07,1.63,2,4.43-.49,7.59-3.44,4.36-6.46,4.46-8.47,2.87A3.59,3.59,0,0,1,916.34,373.14Z"
              transform="translate(-7.07 -6.21)"
            />
            <g>
              <path
                d="M887.53,402.57,890,404.5l6.81-8.62-1.87-1.48.39-.49a7.74,7.74,0,0,0,2.52.92l.59.46-7.72,9.78,2.27,1.79-.5.64L887,403.21Z"
                transform="translate(-7.07 -6.21)"
              />
              <path
                d="M898.39,403.66c2.73-3.46,5.36-4.48,7.34-2.91s1.61,4.36-1.12,7.82-5.41,4.55-7.4,3S895.65,407.12,898.39,403.66Zm5.49,4.33c2.5-3.17,2.8-5.48,1.36-6.62s-3.62-.31-6.12,2.87-2.86,5.55-1.42,6.69S901.37,411.17,903.88,408Z"
                transform="translate(-7.07 -6.21)"
              />
            </g>
          </g>
          <g transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 11.328829, -41.021713)">
            <path
              d="M468.12,496.23l-.44-3.09-10.88,1.57.34,2.35-.62.09a7.53,7.53,0,0,0-1.2-2.4l-.1-.74,12.33-1.78-.41-2.86.8-.11,1,6.85Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M508.79,491.26c-5-3.67-7.75-5.48-10-5.15a2.66,2.66,0,0,0-2.49,3.27,4.13,4.13,0,0,0,2.15,2.82l-.47.68a5,5,0,0,1-2.49-3.47,3.46,3.46,0,0,1,3.15-4.23c2.61-.38,5.46,1.64,9.84,4.74-.15-.73-.3-1.48-.41-2.2l-.64-4.47.81-.12,1.16,8Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M548.11,485.85l-.71-.44a4.68,4.68,0,0,0,1.15-3.87,2.88,2.88,0,0,0-3.34-2.66c-1.8.26-2.85,1.75-2.38,5l-.8.11c-.43-3-1.86-3.91-3.43-3.68a2.42,2.42,0,0,0-2.07,3,4.19,4.19,0,0,0,1.87,2.8l-.54.63a5.08,5.08,0,0,1-2.13-3.33c-.29-2,.66-3.75,2.67-4a3.37,3.37,0,0,1,3.6,2h.08a3.53,3.53,0,0,1,3-3.48,3.73,3.73,0,0,1,4.29,3.48A5.42,5.42,0,0,1,548.11,485.85Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M579.68,475.41c-.61.09-1.67.19-2.28.24v.08c.61.25,1.15.55,1.79.83l6,3.07-1.11-7.75.78-.11,1.28,8.86-.54.08L576,475.86l-.11-.81,13.13-1.9.12.89Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M628.81,474.28l-.7-.43a4.71,4.71,0,0,0,1.08-3.82,3.36,3.36,0,0,0-4-2.75,3.08,3.08,0,0,0-2.92,3.69,4,4,0,0,0,1.22,2.31l-.3.69-6.21.41-.88-6.06.82-.12.75,5.24,4.8-.26a4.31,4.31,0,0,1-1-2.24c-.32-2.22.69-4.19,3.56-4.6a4.21,4.21,0,0,1,5,3.56A5.51,5.51,0,0,1,628.81,474.28Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M664.61,468.56c-5.5.79-7.75-1.22-8.12-3.75a3.61,3.61,0,0,1,.87-3l.7.46a2.89,2.89,0,0,0-.77,2.39c.31,2.1,2.26,3.66,7.19,2.95,3.21-.46,5.27-1.84,4.93-4.18-.22-1.51-1.83-2.44-3.71-2.17s-3.17,1.32-2.88,3.31a4.31,4.31,0,0,0,2.59,3l-1,.15a4.52,4.52,0,0,1-2.41-3.12c-.33-2.29.89-3.85,3.53-4.24a3.88,3.88,0,0,1,4.63,3C670.57,466,668.6,468,664.61,468.56Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M697.58,456.92l1,6.92-.81.12-1.17-8.07.55-.08c4.62,2.55,8,2.84,13.18,2.31l.14,1C705.15,459.66,701.66,459,697.58,456.92Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M747.86,456.81a4.5,4.5,0,0,1-4.09-2.08h-.08a3.71,3.71,0,0,1-2.74,2.31,3.28,3.28,0,0,1-3.71-3,3.32,3.32,0,0,1,2.92-4,4.82,4.82,0,0,1,3.61,1.38h.08c.54-1.22,1.25-2.52,3-2.77s3.66,1.1,4,3.49S749.92,456.52,747.86,456.81Zm-7.55-5.9A2.6,2.6,0,0,0,738,454a2.43,2.43,0,0,0,2.82,2.17c1.86-.27,2.36-2.15,2.79-4A4,4,0,0,0,740.31,450.91Zm6.67-1.35c-2,.29-2.43,2.37-3,4.52a4.07,4.07,0,0,0,3.66,1.85,3.08,3.08,0,0,0,2.42-3.7A2.81,2.81,0,0,0,747,449.56Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M790.46,450.34l-.7-.47a2.87,2.87,0,0,0,.77-2.38c-.31-2.1-2.26-3.66-7.19-2.95-3.21.46-5.27,1.84-4.93,4.18.22,1.51,1.83,2.44,3.7,2.17s3.18-1.32,2.89-3.32a4.3,4.3,0,0,0-2.59-3l1-.15a4.52,4.52,0,0,1,2.41,3.12c.33,2.29-.89,3.85-3.53,4.23a3.87,3.87,0,0,1-4.63-3c-.37-2.62,1.6-4.6,5.59-5.17,5.5-.8,7.75,1.22,8.12,3.75A3.6,3.6,0,0,1,790.46,450.34Z"
              transform="translate(-7.07 -6.21)"
            />
          </g>
          <g transform="matrix(0.987688, -0.156435, 0.156435, 0.987688, -67.063148, 142.589432)">
            <path
              d="M468.12,496.23l-.44-3.09-10.88,1.57.34,2.35-.62.09a7.53,7.53,0,0,0-1.2-2.4l-.1-.74,12.33-1.78-.41-2.86.8-.11,1,6.85Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M508.79,491.26c-5-3.67-7.75-5.48-10-5.15a2.66,2.66,0,0,0-2.49,3.27,4.13,4.13,0,0,0,2.15,2.82l-.47.68a5,5,0,0,1-2.49-3.47,3.46,3.46,0,0,1,3.15-4.23c2.61-.38,5.46,1.64,9.84,4.74-.15-.73-.3-1.48-.41-2.2l-.64-4.47.81-.12,1.16,8Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M548.11,485.85l-.71-.44a4.68,4.68,0,0,0,1.15-3.87,2.88,2.88,0,0,0-3.34-2.66c-1.8.26-2.85,1.75-2.38,5l-.8.11c-.43-3-1.86-3.91-3.43-3.68a2.42,2.42,0,0,0-2.07,3,4.19,4.19,0,0,0,1.87,2.8l-.54.63a5.08,5.08,0,0,1-2.13-3.33c-.29-2,.66-3.75,2.67-4a3.37,3.37,0,0,1,3.6,2h.08a3.53,3.53,0,0,1,3-3.48,3.73,3.73,0,0,1,4.29,3.48A5.42,5.42,0,0,1,548.11,485.85Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M579.68,475.41c-.61.09-1.67.19-2.28.24v.08c.61.25,1.15.55,1.79.83l6,3.07-1.11-7.75.78-.11,1.28,8.86-.54.08L576,475.86l-.11-.81,13.13-1.9.12.89Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M628.81,474.28l-.7-.43a4.71,4.71,0,0,0,1.08-3.82,3.36,3.36,0,0,0-4-2.75,3.08,3.08,0,0,0-2.92,3.69,4,4,0,0,0,1.22,2.31l-.3.69-6.21.41-.88-6.06.82-.12.75,5.24,4.8-.26a4.31,4.31,0,0,1-1-2.24c-.32-2.22.69-4.19,3.56-4.6a4.21,4.21,0,0,1,5,3.56A5.51,5.51,0,0,1,628.81,474.28Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M664.61,468.56c-5.5.79-7.75-1.22-8.12-3.75a3.61,3.61,0,0,1,.87-3l.7.46a2.89,2.89,0,0,0-.77,2.39c.31,2.1,2.26,3.66,7.19,2.95,3.21-.46,5.27-1.84,4.93-4.18-.22-1.51-1.83-2.44-3.71-2.17s-3.17,1.32-2.88,3.31a4.31,4.31,0,0,0,2.59,3l-1,.15a4.52,4.52,0,0,1-2.41-3.12c-.33-2.29.89-3.85,3.53-4.24a3.88,3.88,0,0,1,4.63,3C670.57,466,668.6,468,664.61,468.56Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M697.58,456.92l1,6.92-.81.12-1.17-8.07.55-.08c4.62,2.55,8,2.84,13.18,2.31l.14,1C705.15,459.66,701.66,459,697.58,456.92Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M747.86,456.81a4.5,4.5,0,0,1-4.09-2.08h-.08a3.71,3.71,0,0,1-2.74,2.31,3.28,3.28,0,0,1-3.71-3,3.32,3.32,0,0,1,2.92-4,4.82,4.82,0,0,1,3.61,1.38h.08c.54-1.22,1.25-2.52,3-2.77s3.66,1.1,4,3.49S749.92,456.52,747.86,456.81Zm-7.55-5.9A2.6,2.6,0,0,0,738,454a2.43,2.43,0,0,0,2.82,2.17c1.86-.27,2.36-2.15,2.79-4A4,4,0,0,0,740.31,450.91Zm6.67-1.35c-2,.29-2.43,2.37-3,4.52a4.07,4.07,0,0,0,3.66,1.85,3.08,3.08,0,0,0,2.42-3.7A2.81,2.81,0,0,0,747,449.56Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M790.46,450.34l-.7-.47a2.87,2.87,0,0,0,.77-2.38c-.31-2.1-2.26-3.66-7.19-2.95-3.21.46-5.27,1.84-4.93,4.18.22,1.51,1.83,2.44,3.7,2.17s3.18-1.32,2.89-3.32a4.3,4.3,0,0,0-2.59-3l1-.15a4.52,4.52,0,0,1,2.41,3.12c.33,2.29-.89,3.85-3.53,4.23a3.87,3.87,0,0,1-4.63-3c-.37-2.62,1.6-4.6,5.59-5.17,5.5-.8,7.75,1.22,8.12,3.75A3.6,3.6,0,0,1,790.46,450.34Z"
              transform="translate(-7.07 -6.21)"
            />
          </g>
          <g transform="matrix(0.997951, -0.08731, 0.088682, 1.013638, -42.549599, 72.623741)">
            <path
              d="M496.78,596.45l-1.22-2.87-10.11,4.3.93,2.19-.58.25a7.62,7.62,0,0,0-1.77-2l-.29-.69,11.46-4.87-1.13-2.66.75-.32,2.71,6.38Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M534.83,581.25c-5.78-2.27-8.9-3.32-11-2.41a2.67,2.67,0,0,0-1.57,3.8,4.09,4.09,0,0,0,2.8,2.17l-.28.78a5,5,0,0,1-3.29-2.71,3.47,3.47,0,0,1,2-4.9c2.43-1,5.7.19,10.73,2.06-.33-.66-.67-1.35-1-2l-1.77-4.16.76-.32,3.18,7.48Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M571.45,566l-.79-.24a4.69,4.69,0,0,0,.12-4,2.89,2.89,0,0,0-3.91-1.71c-1.67.71-2.31,2.41-1,5.45l-.74.32c-1.2-2.82-2.81-3.3-4.27-2.68a2.43,2.43,0,0,0-1.23,3.43,4.27,4.27,0,0,0,2.53,2.23l-.36.75a5.07,5.07,0,0,1-2.91-2.68c-.8-1.88-.33-3.79,1.54-4.58a3.34,3.34,0,0,1,4,1l.08,0a3.53,3.53,0,0,1,2-4.13,3.73,3.73,0,0,1,5,2.27A5.43,5.43,0,0,1,571.45,566Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M599.3,547.79c-.56.24-1.56.62-2.14.82l0,.08c.65.08,1.25.23,1.95.34l6.57,1.44-3.06-7.21.73-.31,3.5,8.24-.5.21-10.48-2.24-.32-.75,12.21-5.19.35.82Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M646.51,534.14l-.79-.24a4.69,4.69,0,0,0,.07-4,3.37,3.37,0,0,0-4.57-1.64,3.09,3.09,0,0,0-1.88,4.32,4,4,0,0,0,1.77,1.92l-.11.74-5.9,2-2.4-5.64.76-.32,2.07,4.87,4.57-1.48a4.27,4.27,0,0,1-1.56-1.9c-.88-2.07-.4-4.23,2.26-5.36a4.21,4.21,0,0,1,5.73,2.17A5.5,5.5,0,0,1,646.51,534.14Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M679.66,519.45c-5.12,2.17-7.81.8-8.81-1.55a3.61,3.61,0,0,1,.08-3.09l.8.28a2.83,2.83,0,0,0-.13,2.5c.82,1.95,3.11,3,7.7,1,3-1.27,4.62-3.13,3.7-5.3-.6-1.41-2.4-1.89-4.14-1.15s-2.74,2.09-2,3.94a4.33,4.33,0,0,0,3.27,2.21l-.89.4a4.57,4.57,0,0,1-3.13-2.4c-.91-2.14-.12-4,2.34-5a3.85,3.85,0,0,1,5.23,1.72C684.77,515.45,683.36,517.87,679.66,519.45Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M708.56,499.76l2.73,6.44-.76.32-3.19-7.5.51-.22c5.12,1.28,8.49.7,13.34-1.14l.38.92C716.57,500.47,713,500.76,708.56,499.76Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M757.13,486.8a4.5,4.5,0,0,1-4.48-1l-.08,0a3.7,3.7,0,0,1-2.06,2.93,3.26,3.26,0,0,1-4.34-1.92,3.31,3.31,0,0,1,1.78-4.65,4.83,4.83,0,0,1,3.85.41l.08,0c.2-1.32.55-2.75,2.19-3.45s3.83.13,4.77,2.35S759.05,486,757.13,486.8ZM748.32,483a2.6,2.6,0,0,0-1.44,3.56,2.43,2.43,0,0,0,3.28,1.38c1.73-.74,1.72-2.68,1.68-4.54A4,4,0,0,0,748.32,483Zm6.1-3c-1.87.8-1.74,2.91-1.69,5.12a4,4,0,0,0,4,.85,3.07,3.07,0,0,0,1.39-4.19A2.81,2.81,0,0,0,754.42,480Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M796.65,469.64l-.79-.27a2.86,2.86,0,0,0,.13-2.5c-.83-2-3.11-3-7.7-1-3,1.26-4.62,3.12-3.7,5.3.6,1.41,2.4,1.89,4.14,1.15s2.74-2.09,1.95-3.95a4.36,4.36,0,0,0-3.27-2.21l.89-.39a4.54,4.54,0,0,1,3.13,2.4c.9,2.13.12,3.95-2.34,5a3.86,3.86,0,0,1-5.23-1.72c-1-2.44.36-4.86,4.07-6.43,5.11-2.18,7.81-.81,8.81,1.55A3.58,3.58,0,0,1,796.65,469.64Z"
              transform="translate(-7.07 -6.21)"
            />
          </g>
          <g transform="matrix(0.999391, -0.0349, 0.0349, 0.999391, -17.768068, 33.789829)">
            <path
              d="M550.18,686l-1.92-2.46-8.68,6.74,1.46,1.88-.5.39a7.34,7.34,0,0,0-2.22-1.5l-.46-.59,9.84-7.65-1.77-2.28.64-.49,4.25,5.47Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M583.06,661.53c-6.16-.71-9.45-.92-11.27.49a2.68,2.68,0,0,0-.55,4.08,4.16,4.16,0,0,0,3.27,1.39l-.08.82a5,5,0,0,1-3.87-1.78,3.46,3.46,0,0,1,.64-5.24c2.09-1.62,5.56-1.27,10.9-.75-.49-.55-1-1.13-1.44-1.71l-2.77-3.57.64-.5,5,6.42Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M614.56,637.39l-.83,0a4.7,4.7,0,0,0-.91-3.94,2.89,2.89,0,0,0-4.22-.66c-1.44,1.12-1.61,2.93.41,5.54l-.64.5c-1.87-2.42-3.55-2.48-4.81-1.5a2.43,2.43,0,0,0-.31,3.63,4.23,4.23,0,0,0,3,1.5l-.15.82a5.07,5.07,0,0,1-3.5-1.84c-1.25-1.62-1.29-3.58.32-4.83a3.35,3.35,0,0,1,4.13,0l.07-.05A3.54,3.54,0,0,1,608,632a3.73,3.73,0,0,1,5.45.9A5.43,5.43,0,0,1,614.56,637.39Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M636.84,612.7c-.49.38-1.36,1-1.86,1.34l0,.06c.64-.08,1.26-.09,2-.16l6.72-.3-4.81-6.18.63-.49L645,614l-.43.34-10.7.51-.5-.65,10.47-8.14.55.71Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M679,587.42l-.82,0a4.69,4.69,0,0,0-.95-3.85,3.38,3.38,0,0,0-4.84-.42,3.1,3.1,0,0,0-.71,4.66,4,4,0,0,0,2.2,1.4l.08.75-5.19,3.43L665,588.52l.65-.5,3.25,4.18,4-2.6a4.37,4.37,0,0,1-2-1.44c-1.38-1.78-1.47-4,.82-5.76a4.21,4.21,0,0,1,6.09.63A5.49,5.49,0,0,1,679,587.42Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M707.27,564.74c-4.39,3.41-7.34,2.78-8.92.76a3.65,3.65,0,0,1-.7-3l.84.06A2.87,2.87,0,0,0,699,565c1.3,1.67,3.77,2.06,7.71-1,2.56-2,3.66-4.2,2.21-6.06-.94-1.21-2.8-1.22-4.29-.06s-2.11,2.72-.87,4.31a4.31,4.31,0,0,0,3.72,1.3l-.76.61a4.55,4.55,0,0,1-3.64-1.52c-1.42-1.83-1.13-3.79,1-5.43a3.85,3.85,0,0,1,5.5.33C711.18,559.57,710.45,562.27,707.27,564.74Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M730.17,538.32l4.29,5.52-.65.5-5-6.43.44-.34c5.27-.07,8.37-1.5,12.59-4.51l.61.78C738.1,537,734.76,538.14,730.17,538.32Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M773.81,513.36a4.48,4.48,0,0,1-4.58.21l-.07.06a3.69,3.69,0,0,1-1.23,3.35,3.28,3.28,0,0,1-4.7-.74,3.34,3.34,0,0,1,.54-5,4.8,4.8,0,0,1,3.82-.58l.07-.05c-.14-1.33-.17-2.81,1.24-3.9s3.73-.85,5.21,1.05S775.46,512.08,773.81,513.36ZM764.33,512a2.6,2.6,0,0,0-.48,3.81,2.43,2.43,0,0,0,3.52.49c1.48-1.16,1-3,.46-4.82A4,4,0,0,0,764.33,512Zm5.13-4.48c-1.61,1.25-.94,3.26-.32,5.39a4,4,0,0,0,4.09-.21,3.06,3.06,0,0,0,.27-4.4A2.81,2.81,0,0,0,769.46,507.49Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M807.63,486.67l-.83-.06a2.88,2.88,0,0,0-.52-2.46c-1.3-1.67-3.77-2.06-7.7,1-2.56,2-3.67,4.2-2.22,6.07.94,1.21,2.8,1.22,4.3,0s2.1-2.72.87-4.31a4.32,4.32,0,0,0-3.73-1.3l.76-.61a4.54,4.54,0,0,1,3.64,1.52c1.42,1.83,1.13,3.79-1,5.43a3.86,3.86,0,0,1-5.5-.32c-1.62-2.09-.89-4.79,2.29-7.26,4.39-3.41,7.35-2.78,8.92-.75A3.57,3.57,0,0,1,807.63,486.67Z"
              transform="translate(-7.07 -6.21)"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, -2.617791, 2.08374)">
            <path
              d="M624.57,758.85l-2.48-1.9-6.67,8.74,1.89,1.44-.38.51a7.4,7.4,0,0,0-2.54-.88l-.59-.46,7.56-9.9-2.3-1.75.49-.65,5.51,4.2Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M650.11,726.82c-6.14.88-9.37,1.52-10.77,3.35a2.67,2.67,0,0,0,.51,4.09,4.15,4.15,0,0,0,3.51.5l.14.81a5,5,0,0,1-4.2-.73,3.45,3.45,0,0,1-.72-5.22c1.6-2.1,5-2.65,10.35-3.52-.62-.41-1.26-.84-1.84-1.28l-3.59-2.74.5-.65,6.46,4.93Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M674.38,695.42l-.81.18a4.7,4.7,0,0,0-1.89-3.57,2.88,2.88,0,0,0-4.24.44c-1.1,1.45-.81,3.25,1.81,5.25l-.49.64c-2.43-1.85-4.07-1.48-5-.22a2.42,2.42,0,0,0,.63,3.59,4.24,4.24,0,0,0,3.29.69l.06.83a5,5,0,0,1-3.85-.89c-1.63-1.24-2.16-3.13-.93-4.75a3.36,3.36,0,0,1,4-1.06l.05-.07a3.54,3.54,0,0,1-.31-4.58,3.74,3.74,0,0,1,5.5-.51A5.38,5.38,0,0,1,674.38,695.42Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M689.6,665.85c-.37.49-1.05,1.31-1.45,1.77l.06,0c.61-.24,1.2-.41,1.86-.66l6.43-2-6.23-4.75.48-.63,7.12,5.42-.33.44-10.22,3.23-.65-.49,8.05-10.55.71.54Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M723.88,630.64l-.8.18a4.7,4.7,0,0,0-1.91-3.49,3.37,3.37,0,0,0-4.78.84,3.1,3.1,0,0,0,.5,4.69,4,4,0,0,0,2.49.79l.27.7L715.51,639l-4.87-3.71.5-.65,4.2,3.21,3.25-3.55a4.4,4.4,0,0,1-2.31-.88c-1.78-1.36-2.43-3.48-.68-5.78a4.21,4.21,0,0,1,6-1A5.49,5.49,0,0,1,723.88,630.64Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M745.43,601.48c-3.37,4.41-6.39,4.56-8.43,3a3.62,3.62,0,0,1-1.45-2.72l.83-.15a2.86,2.86,0,0,0,1.12,2.24c1.69,1.28,4.17,1,7.2-2.93,2-2.58,2.46-5,.59-6.44-1.22-.93-3-.46-4.17,1s-1.34,3.17.26,4.39a4.33,4.33,0,0,0,3.93.3l-.57.79a4.58,4.58,0,0,1-3.91-.54c-1.84-1.41-2.06-3.38-.44-5.5a3.86,3.86,0,0,1,5.4-1.1C747.89,595.48,747.87,598.28,745.43,601.48Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M760.81,570.08l5.55,4.24-.49.65L759.39,570l.33-.45c5.08-1.41,7.72-3.58,11-7.57l.79.6C768.13,566.73,765.2,568.73,760.81,570.08Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M796.61,534.78a4.49,4.49,0,0,1-4.37,1.38l0,.07a3.72,3.72,0,0,1-.34,3.56,3.27,3.27,0,0,1-4.73.48,3.33,3.33,0,0,1-.75-4.93,4.83,4.83,0,0,1,3.55-1.54l.05-.06c-.47-1.25-.88-2.68.2-4.09s3.39-1.78,5.31-.32S797.88,533.13,796.61,534.78Zm-9.52,1.08a2.6,2.6,0,0,0,.51,3.81,2.43,2.43,0,0,0,3.53-.43c1.14-1.5.17-3.18-.79-4.78A4,4,0,0,0,787.09,535.86Zm3.81-5.64c-1.23,1.62-.07,3.4,1.07,5.29a4,4,0,0,0,3.9-1.24,3.06,3.06,0,0,0-.86-4.33A2.81,2.81,0,0,0,790.9,530.22Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M822.48,500.33l-.82.15a2.87,2.87,0,0,0-1.13-2.24c-1.68-1.29-4.17-1-7.19,2.93-2,2.58-2.47,5-.59,6.44,1.21.93,3,.46,4.16-1.05s1.35-3.17-.26-4.39a4.33,4.33,0,0,0-3.93-.3l.58-.79a4.55,4.55,0,0,1,3.91.54c1.84,1.41,2.06,3.38.44,5.5a3.87,3.87,0,0,1-5.4,1.1c-2.11-1.61-2.09-4.41.36-7.61,3.37-4.42,6.39-4.56,8.42-3A3.61,3.61,0,0,1,822.48,500.33Z"
              transform="translate(-7.07 -6.21)"
            />
          </g>
          <g transform="matrix(0.999848, 0.017452, -0.017452, 0.999848, 5.695636, -12.180771)">
            <path
              d="M715.21,810.31l-2.88-1.19-4.21,10.15,2.19.91-.24.59a7.66,7.66,0,0,0-2.67-.21l-.69-.28,4.77-11.51-2.67-1.11.31-.75,6.4,2.66Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M731.71,772.81c-5.71,2.44-8.67,3.87-9.56,6a2.67,2.67,0,0,0,1.55,3.82,4.11,4.11,0,0,0,3.52-.41l.34.75a5,5,0,0,1-4.25.37,3.46,3.46,0,0,1-2-4.87c1-2.43,4.2-3.85,9.1-6-.7-.24-1.43-.49-2.1-.77l-4.17-1.73.31-.76,7.51,3.11Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M747.14,736.25l-.74.39a4.65,4.65,0,0,0-2.74-3,2.87,2.87,0,0,0-4,1.51c-.7,1.68,0,3.34,3.09,4.61l-.31.75c-2.83-1.18-4.31-.39-4.92,1.07a2.43,2.43,0,0,0,1.53,3.31,4.19,4.19,0,0,0,3.35-.18l.27.79a5,5,0,0,1-4,.12c-1.89-.78-2.88-2.47-2.11-4.35a3.36,3.36,0,0,1,3.59-2l0-.08a3.54,3.54,0,0,1-1.46-4.34,3.74,3.74,0,0,1,5.18-1.91A5.42,5.42,0,0,1,747.14,736.25Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M754.29,703.78c-.23.56-.68,1.53-.95,2.08l.07,0c.53-.39,1.06-.7,1.63-1.11l5.7-3.59-7.23-3,.3-.74,8.27,3.43-.21.51-9,5.73-.75-.31,5.08-12.26.82.35Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M778.43,661l-.74.38a4.63,4.63,0,0,0-2.73-2.88,3.37,3.37,0,0,0-4.41,2,3.09,3.09,0,0,0,1.68,4.4,4.06,4.06,0,0,0,2.62.13l.43.61-2.82,5.55-5.65-2.35.31-.76,4.89,2,2.23-4.26a4.33,4.33,0,0,1-2.45-.26c-2.08-.86-3.25-2.74-2.14-5.41a4.22,4.22,0,0,1,5.61-2.47A5.55,5.55,0,0,1,778.43,661Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M791.79,627.26c-2.12,5.14-5,6-7.37,5.07a3.64,3.64,0,0,1-2.1-2.26l.76-.36a2.83,2.83,0,0,0,1.66,1.87c2,.82,4.29-.07,6.2-4.67,1.24-3,1.11-5.47-1.07-6.37-1.42-.59-3,.32-3.76,2.07s-.49,3.41,1.37,4.18a4.33,4.33,0,0,0,3.88-.71L791,627a4.56,4.56,0,0,1-3.92.47c-2.14-.88-2.86-2.73-1.84-5.2a3.87,3.87,0,0,1,5-2.44C792.64,620.83,793.34,623.54,791.79,627.26Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M798.63,593l6.46,2.68-.32.76-7.53-3.12.21-.52c4.55-2.66,6.55-5.44,8.73-10.14l.91.38C804.85,587.86,802.53,590.55,798.63,593Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M824.22,549.7a4.53,4.53,0,0,1-3.88,2.45l0,.07a3.74,3.74,0,0,1,.58,3.54,3.27,3.27,0,0,1-4.44,1.67,3.33,3.33,0,0,1-2-4.57,4.77,4.77,0,0,1,3-2.4l0-.08c-.78-1.08-1.53-2.36-.85-4s2.82-2.59,5.05-1.66S825,547.77,824.22,549.7Zm-8.93,3.47a2.59,2.59,0,0,0,1.46,3.55,2.43,2.43,0,0,0,3.3-1.31c.72-1.74-.64-3.12-2-4.42A4,4,0,0,0,815.29,553.17Zm2.24-6.42c-.78,1.88.79,3.3,2.39,4.84a4.06,4.06,0,0,0,3.45-2.2,3.08,3.08,0,0,0-1.94-4A2.82,2.82,0,0,0,817.53,546.75Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M840.41,509.77l-.76.36a2.85,2.85,0,0,0-1.66-1.88c-2-.81-4.29.07-6.2,4.67-1.24,3-1.11,5.47,1.07,6.38,1.42.58,3-.33,3.76-2.08s.49-3.41-1.37-4.18a4.33,4.33,0,0,0-3.88.71l.35-.9a4.53,4.53,0,0,1,3.92-.48c2.14.89,2.86,2.74,1.84,5.2a3.87,3.87,0,0,1-5,2.44c-2.44-1-3.14-3.72-1.59-7.44,2.12-5.13,5-6,7.37-5.06A3.56,3.56,0,0,1,840.41,509.77Z"
              transform="translate(-7.07 -6.21)"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, -3.498184, 6.398079)">
            <path
              d="M816,836.84l-3.09-.42-1.47,10.9,2.35.31-.08.63a7.39,7.39,0,0,0-2.64.49l-.74-.1L812,836.3l-2.86-.38.11-.81,6.86.93Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M822.39,796.37c-4.9,3.81-7.39,6-7.7,8.24a2.68,2.68,0,0,0,2.47,3.3,4.11,4.11,0,0,0,3.29-1.3l.53.64a5,5,0,0,1-4,1.44,3.45,3.45,0,0,1-3.21-4.18c.35-2.62,3.07-4.8,7.25-8.17-.74-.06-1.51-.11-2.23-.21l-4.48-.61.11-.81,8.06,1.09Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M828,757.08l-.62.56a4.65,4.65,0,0,0-3.41-2.17,2.87,2.87,0,0,0-3.46,2.48c-.25,1.8.89,3.22,4.17,3.66l-.11.81c-3-.41-4.27.72-4.48,2.29a2.42,2.42,0,0,0,2.32,2.81,4.21,4.21,0,0,0,3.2-1l.46.69a5,5,0,0,1-3.78,1.13c-2-.27-3.43-1.65-3.16-3.66a3.35,3.35,0,0,1,2.95-2.91v-.08a3.53,3.53,0,0,1-2.53-3.83,3.74,3.74,0,0,1,4.52-3.17A5.46,5.46,0,0,1,828,757.08Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M826.57,723.85c-.09.61-.27,1.66-.4,2.26h.09c.4-.51.84-1,1.29-1.49l4.59-4.93-7.76-1,.1-.79,8.88,1.2-.08.54L826,727.47l-.81-.11L827,714.22l.88.11Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M838.94,676.29l-.61.56a4.66,4.66,0,0,0-3.38-2.09,3.37,3.37,0,0,0-3.74,3.1,3.1,3.1,0,0,0,2.75,3.82,4.06,4.06,0,0,0,2.56-.55l.58.48-1.31,6.09-6.06-.82.11-.82,5.24.71,1.07-4.68a4.39,4.39,0,0,1-2.44.37c-2.23-.3-3.84-1.82-3.45-4.69a4.21,4.21,0,0,1,4.79-3.81A5.47,5.47,0,0,1,838.94,676.29Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M843.25,640.29c-.75,5.51-3.3,7.13-5.84,6.78a3.56,3.56,0,0,1-2.6-1.65l.63-.54a2.89,2.89,0,0,0,2.09,1.39c2.1.29,4.14-1.16,4.8-6.1.44-3.22-.33-5.57-2.67-5.89-1.51-.2-2.85,1.09-3.1,3s.4,3.42,2.4,3.69a4.32,4.32,0,0,0,3.57-1.68l-.12,1a4.53,4.53,0,0,1-3.66,1.47c-2.3-.31-3.46-1.91-3.11-4.56a3.87,3.87,0,0,1,4.16-3.62C842.42,633.86,843.79,636.3,843.25,640.29Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M841.08,605.39l6.93.94-.11.81-8.08-1.09.08-.55c3.71-3.74,4.93-6.93,5.83-12l1,.13C845.79,598.86,844.23,602.05,841.08,605.39Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M854.75,557a4.51,4.51,0,0,1-3.12,3.36v.09a3.72,3.72,0,0,1,1.47,3.26,3.28,3.28,0,0,1-3.87,2.75,3.31,3.31,0,0,1-3.09-3.91,4.77,4.77,0,0,1,2.32-3.09v-.09c-1-.84-2.09-1.88-1.85-3.65s2.07-3.22,4.46-2.9S855,555,854.75,557ZM847,562.65a2.61,2.61,0,0,0,2.32,3.07,2.43,2.43,0,0,0,2.85-2.12c.26-1.87-1.41-2.85-3-3.77A4,4,0,0,0,847,562.65Zm.52-6.78c-.27,2,1.61,3,3.54,4.07a4,4,0,0,0,2.78-3,3.07,3.07,0,0,0-2.89-3.34A2.81,2.81,0,0,0,847.53,555.87Z"
              transform="translate(-7.07 -6.21)"
            />
            <path
              d="M860.19,514.27l-.64.54a2.88,2.88,0,0,0-2.08-1.39c-2.11-.28-4.14,1.17-4.81,6.11-.43,3.21.33,5.57,2.67,5.88,1.52.21,2.85-1.09,3.11-3s-.4-3.41-2.4-3.68a4.34,4.34,0,0,0-3.57,1.67l.11-1a4.5,4.5,0,0,1,3.67-1.46c2.29.3,3.46,1.91,3.1,4.56a3.86,3.86,0,0,1-4.15,3.62c-2.62-.35-4-2.8-3.45-6.79.74-5.5,3.3-7.12,5.84-6.78A3.6,3.6,0,0,1,860.19,514.27Z"
              transform="translate(-7.07 -6.21)"
            />
          </g>
        </g>
        <rect x="439.467" y="0.5" width="3.221" height="414.389" />
        <rect
          x="417.084"
          y="3.24"
          width="3.221"
          height="414.389"
          transform="matrix(0, 1, -1, 0, 651.511719, -210.994568)"
        />
        <circle className="cls-11" cx="441.34" cy="207.866" r="13.119" />
        <g transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)">
          <title>Circle Lines</title>
          <line
            className="cls-12"
            x1="860"
            y1="429.6"
            x2="739.55"
            y2="838.861"
          />
          <line
            className="cls-12"
            x1="860.03"
            y1="429.6"
            x2="639.41"
            y2="792.049"
          />
          <line
            className="cls-12"
            x1="860.03"
            y1="429.6"
            x2="561.671"
            y2="735.161"
          />
          <line
            className="cls-12"
            x1="860.03"
            y1="429.6"
            x2="505.292"
            y2="666.666"
          />
          <line
            className="cls-12"
            x1="860.03"
            y1="429.6"
            x2="466.386"
            y2="589.871"
          />
          <line
            className="cls-12"
            x1="860.03"
            y1="429.6"
            x2="438.933"
            y2="513.736"
          />
        </g>
        <g
          onClick={() => {
            setModal((pre) => ({
              show: pre.show ? false : true,
              image: pre.image ? undefined : soulImage,
            }));
          }}
          // onMouseEnter={() => setModal({ show: true, image: soulImage })}
          // onMouseLeave={() => setModal({ show: false, image: undefined })}
          style={{ cursor: 'pointer' }}
          transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)"
        >
          <path
            d="M1224.64,798.23l-1.23-6.23a15.65,15.65,0,0,0,10-5.29c2.79-3.5,2.73-6.7.3-8.63s-5-.68-8.43,1.19l-5.24,2.79c-3.71,2.05-8.71,3.34-13.26-.28-5-3.95-5.3-11.37-.46-17.45a17.48,17.48,0,0,1,11.61-6.44l1.25,5.63a12.85,12.85,0,0,0-8.06,4.63c-2.35,3-2.5,6-.23,7.79,2.45,1.95,5.24.3,8.36-1.44l5.1-2.8c4.49-2.47,8.9-3.2,13.41.39,5,4,5.92,11.56.36,18.56A20.5,20.5,0,0,1,1224.64,798.23Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M1234,762.47c-10.07-8-11.54-18.37-5.08-26.49s16.9-9,26.95-1,11.79,18.57,5.33,26.69S1244.06,770.49,1234,762.47Zm17.35-21.79c-7-5.57-13.74-5.77-17.63-.88s-2.19,11.43,4.8,17,14,6,17.88,1.07S1258.32,746.24,1251.33,740.68Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M1262.75,730.51l-17.48-13.92,4.39-5.52,17.91,14.25c6.78,5.4,11,4.64,14,.87s2.89-8.21-3.89-13.61l-17.9-14.25L1264,693l17.49,13.92c10.22,8.15,10.61,15.86,4.87,23.08S1273,738.66,1262.75,730.51Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M1270.22,685.25l4.39-5.52,26.5,21.09,10.29-12.93,4.65,3.7-14.69,18.46Z"
            transform="translate(-7.07 -6.21)"
          />
        </g>
        <g
          onClick={() =>
            setModal((pre) => ({
              show: pre.show ? false : true,
              image: pre.image ? undefined : bodyImage,
            }))
          }
          // onMouseEnter={() => setModal({ show: true, image: bodyImage })}
          // onMouseLeave={() => setModal({ show: false, image: undefined })}
          style={{ cursor: 'pointer' }}
          transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)"
        >
          <path
            d="M419.05,193.85l6.89-10.71c4.43-6.88,9.79-10.57,15.94-6.62,3.15,2,5.11,5.73,4.25,9.31l.21.14c3.22-3.17,7.55-4.23,12.08-1.32,6.69,4.31,6.29,11.81,1.55,19.18l-7.45,11.57Zm20.37-1.28c3-4.67,2.46-7.82-.45-9.7-3.23-2.08-5.87-.68-8.66,3.66l-2.81,4.36,9.19,5.92Zm15.52,8.9c3.17-4.91,3.17-8.79-.59-11.22-3.52-2.26-6.77-.59-10,4.4l-3.23,5,10.58,6.81Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M454.19,175.75c-10.83-7-13.33-17.12-7.72-25.84s15.93-10.63,26.72-3.68,13.6,17.3,8,26S465,182.72,454.19,175.75Zm15.08-23.42c-7.52-4.83-14.25-4.36-17.64.9s-1,11.58,6.49,16.42,14.51,4.54,17.89-.72S476.78,157.17,469.27,152.33Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M460.93,128.81l5.74-8.92c6.61-10.26,16-12.76,27.11-5.63s13,16.92,6.56,26.87l-5.95,9.23Zm34.15,9.92c4.34-6.73,3-13.07-5.23-18.37s-14.28-3.72-18.62,3L469.56,126l23.85,15.36Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M505.48,105.8,478,102.34,482.06,96,493,97.86c3.12.62,6.05,1.17,9.28,1.76l.13-.21c-1.87-2.69-3.51-5.24-5.37-7.82l-6.22-9.15,4-6.18L509.3,99.87l12.45,8-3.82,5.94Z"
            transform="translate(-7.07 -6.21)"
          />
        </g>
        <g
          onClick={() =>
            setModal((pre) => ({
              show: pre.show ? false : true,
              image: pre.image ? undefined : mindImage,
            }))
          }
          // onMouseEnter={() => setModal({ show: true, image: mindImage })}
          // onMouseLeave={() => setModal({ show: false, image: undefined })}
          style={{ cursor: 'pointer' }}
          transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)"
        >
          <path
            d="M1209.11,53.74l5.49,5.8L1205.29,78c-1.24,2.37-2.63,4.8-3.92,7.22l.17.18c2.49-1.15,5-2.44,7.4-3.55l18.87-8.35,5.48,5.81-28.93,27.34-4.45-4.71,13.24-12.51c2.67-2.52,6.91-5.75,9.82-8l-.17-.18-8.93,4-17.49,7.53-2.88-3L1202,72.67l4.55-8.65-.17-.18c-2.44,2.78-5.91,6.82-8.58,9.35L1184.55,85.7l-4.37-4.62Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M1240.26,86.69l4.84,5.13-28.93,27.34-4.85-5.13Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M1252,99.16l5,5.28-7.77,24.36-3.35,8.82.17.18c2.75-3.07,6-6.94,9.25-10l12.57-11.87,4.61,4.88-28.93,27.35-5-5.28,7.79-24.38,3.32-8.8-.16-.18c-2.83,3.14-5.93,6.84-9.15,9.89l-12.67,12-4.62-4.88Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M1279.47,128.18l7.29,7.72c8.37,8.86,8.55,18.61-1,27.66s-19.53,8.54-27.66-.06l-7.54-8ZM1261.66,159c5.51,5.82,12,6,19.09-.68s7-13,1.53-18.8l-2.12-2.25-20.62,19.48Z"
            transform="translate(-7.07 -6.21)"
          />
        </g>
        <g transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)">
          <path
            d="M371.76,562.1l5.8,2.59a15.61,15.61,0,0,0-1.45,11.23c1.25,4.3,3.89,6.09,6.87,5.22,3.14-.91,3.43-3.67,3.89-7.57l.74-5.88c.47-4.22,2.3-9.05,7.88-10.68,6.09-1.78,12.35,2.23,14.52,9.69a17.48,17.48,0,0,1-1.44,13.2l-5.32-2.23a12.83,12.83,0,0,0,.87-9.25c-1.06-3.63-3.44-5.49-6.23-4.68-3,.88-3.27,4.11-3.64,7.65l-.66,5.79c-.57,5.1-2.52,9.11-8.06,10.73-6.19,1.8-12.86-1.83-15.35-10.41A20.48,20.48,0,0,1,371.76,562.1Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M395.79,590.76c12.06-4.5,21.69-.42,25.31,9.29s-1,19.12-13.05,23.6-22,.54-25.61-9.18S383.72,595.26,395.79,590.76Zm9.73,26.09c8.37-3.12,12-8.79,9.84-14.65s-8.67-7.76-17-4.64-12.32,8.9-10.13,14.77S397.15,620,405.52,616.85Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M410.33,629.6c11.83-5.45,22.27-1.28,26.48,7.88A15.81,15.81,0,0,1,437,650l-5.71-1.53a10.37,10.37,0,0,0,0-8.3c-2.71-5.88-9.63-7.81-17.74-4.07s-11.45,10-8.66,16a11.18,11.18,0,0,0,7.26,6l-2.44,5.28a15.85,15.85,0,0,1-10.51-9C395,645.38,398.36,635.11,410.33,629.6Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M444.2,654.12l3.28,6.25-35.26,18.48-3.28-6.25Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M455.81,675.37,460,682.5l-27.69,31.4-3.8-6.43,15.16-16c3-3.23,6.3-6.54,9.47-9.77l-.12-.21c-4.35,1.3-8.82,2.58-13.1,3.63l-21.34,5.5-3.66-6.21Zm-22.92,7.36,9.53,16.15-4.77,2.81-9.53-16.14Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M468.69,696.58l4,5.84-28,19,9.26,13.69L449,738.42l-13.22-19.53Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M497.56,736.51l5.22,6.43L480.05,778.1l-4.71-5.8L488,754.24c2.48-3.63,5.27-7.4,7.92-11.06l-.15-.19c-4.11,1.92-8.35,3.85-12.42,5.53L463,757.12l-4.54-5.59ZM476,747.19l11.82,14.56-4.3,3.49-11.82-14.56Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M486.43,777.53l6.35-.28a15.58,15.58,0,0,0,3.72,10.69c3.05,3.29,6.21,3.71,8.48,1.6s1.43-4.83.09-8.52l-2-5.59c-1.47-4-2-9.12,2.27-13.08,4.65-4.31,12-3.52,17.32,2.17A17.47,17.47,0,0,1,527.31,777l-5.75.38a12.84,12.84,0,0,0-3.37-8.66c-2.57-2.77-5.53-3.37-7.67-1.39-2.29,2.13-1.08,5.13.18,8.47l2,5.47c1.78,4.81,1.83,9.27-2.4,13.2-4.73,4.38-12.31,4.12-18.39-2.43A20.49,20.49,0,0,1,486.43,777.53Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M535.83,779.37,545,788c6.43,6.06,9.09,12.79,2.77,19.48-6.08,6.46-13.63,4.88-19.87-1l-4.16-3.93-10,10.65-5.13-4.85Zm-4.52,22.57c4.33,4.08,8,4.3,11.36.74s2.29-6.94-2-11l-3.56-3.36-9.52,10.1Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M561.16,802.76,579.65,818l-3.78,4.59-13-10.74-6.49,7.87,11,9.1-3.77,4.59-11-9.1-7.49,9.09,13.51,11.13-3.78,4.58-19-15.61Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M572.5,837.84c7.59-10.59,18.73-12.13,26.92-6.26a15.84,15.84,0,0,1,6.38,10.78l-5.72,1.5a10.43,10.43,0,0,0-4.12-7.21c-5.26-3.77-12.23-2-17.44,5.23s-5,14.35.41,18.24a11.2,11.2,0,0,0,9.29,1.63l.48,5.8a15.88,15.88,0,0,1-13.56-2.6C567,859.12,564.82,848.54,572.5,837.84Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M616.89,851.27l-9.76-6.06,3.13-5L635.78,856l-3.14,5L622.88,855,605,883.76,599,880Z"
            transform="translate(-7.07 -6.21)"
          />
          <path
            d="M620.67,887.32l5.92-2.32a15.65,15.65,0,0,0,7,8.91c3.94,2.12,7.07,1.49,8.54-1.25s-.22-5-2.68-8.08l-3.67-4.66c-2.68-3.29-4.85-8-2.09-13.1,3-5.59,10.24-7.24,17.08-3.56a17.44,17.44,0,0,1,8.41,10.28l-5.32,2.23a12.84,12.84,0,0,0-6-7.11c-3.32-1.78-6.33-1.39-7.7,1.17-1.48,2.76.64,5.21,2.9,8l3.67,4.53c3.24,4,4.73,8.18,2,13.26-3.05,5.69-10.31,7.89-18.19,3.66A20.57,20.57,0,0,1,620.67,887.32Z"
            transform="translate(-7.07 -6.21)"
          />
        </g>
        <g transform="matrix(0.482864, 0, 0, 0.482864, 26.80463, 18.660547)">
          <text
            onClick={() =>
              setModal((pre) => ({
                show: pre.show ? false : true,
                image: pre.image ? undefined : educationImage,
              }))
            }
            // onMouseEnter={() => setModal({ show: true, image: educationImage })}
            // onMouseLeave={() => setModal({ show: false, image: undefined })}
            style={{ cursor: 'pointer' }}
            fontSize="2em"
            x="150.38"
            y="446.822"
          >
            Education
          </text>
          <text
            onClick={() =>
              setModal((pre) => ({
                show: pre.show ? false : true,
                image: pre.image ? undefined : moneyImage,
              }))
            }
            // onMouseEnter={() => setModal({ show: true, image: moneyImage })}
            // onMouseLeave={() => setModal({ show: false, image: undefined })}
            style={{ cursor: 'pointer' }}
            fontSize="2em"
            x="105.436"
            y="526.539"
          >
            Money/Income
          </text>
          <text
            onClick={() =>
              setModal((pre) => ({
                show: pre.show ? false : true,
                image: pre.image ? undefined : careerImage,
              }))
            }
            // onMouseEnter={() => setModal({ show: true, image: careerImage })}
            // onMouseLeave={() => setModal({ show: false, image: undefined })}
            style={{ cursor: 'pointer' }}
            fontSize="2em"
            x="47.807"
            y="600.928"
          >
            Career/Occupation
          </text>
          <text
            onClick={() =>
              setModal((pre) => ({
                show: pre.show ? false : true,
                image: pre.image ? undefined : loveImage,
              }))
            }
            // onMouseEnter={() => setModal({ show: true, image: loveImage })}
            // onMouseLeave={() => setModal({ show: false, image: undefined })}
            style={{ cursor: 'pointer' }}
            fontSize="2em"
            x="59.984"
            y="674.971"
          >
            Relationship/Love
          </text>
          <text
            onClick={() =>
              setModal((pre) => ({
                show: pre.show ? false : true,
                image: pre.image ? undefined : sexualityImage,
              }))
            }
            // onMouseEnter={() => setModal({ show: true, image: sexualityImage })}
            // onMouseLeave={() => setModal({ show: false, image: undefined })}
            style={{ cursor: 'pointer' }}
            fontSize="2em"
            x="173.781"
            y="737.718"
          >
            Sexuality
          </text>
          <text
            onClick={() =>
              setModal((pre) => ({
                show: pre.show ? false : true,
                image: pre.image ? undefined : environmentImage,
              }))
            }
            // onMouseEnter={() =>
            //   setModal({ show: true, image: environmentImage })
            // }
            // onMouseLeave={() => setModal({ show: false, image: undefined })}
            style={{ cursor: 'pointer' }}
            fontSize="2em"
            x="144.528"
            y="787.003"
          >
            Enviroment
          </text>
          <text
            onClick={() =>
              setModal((pre) => ({
                show: pre.show ? false : true,
                image: pre.image ? undefined : peaceImage,
              }))
            }
            // onMouseEnter={() => setModal({ show: true, image: peaceImage })}
            // onMouseLeave={() => setModal({ show: false, image: undefined })}
            style={{ cursor: 'pointer' }}
            fontSize="2em"
            x="99.857"
            y="830.393"
          >
            Peace/Conflict
          </text>
        </g>
        <circle className="cls-13" cx="78.009" cy="58.598" r="6.499" />
        <circle className="cls-14" cx="78.009" cy="77.913" r="6.499" />
        <circle className="cls-15" cx="78.009" cy="97.227" r="6.499" />
        <g transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)">
          <g>
            <path d="M133.91,110.05h1.22v17.47h-1.22V110.05z" />
            <path d="M141.72,125.81v-17.36h1.17v17.52c0,0.57,0.23,0.83,0.55,0.83c0.11,0,0.22,0,0.48-0.05l0.2,0.95 c-0.24,0.07-0.46,0.13-0.84,0.13C142.25,127.83,141.72,127.2,141.72,125.81z" />
            <path d="M149.59,125.81v-17.36h1.17v17.52c0,0.57,0.23,0.83,0.55,0.83c0.11,0,0.22,0,0.48-0.05l0.2,0.95 c-0.24,0.07-0.46,0.13-0.84,0.13C150.12,127.83,149.59,127.2,149.59,125.81z" />
            <path d="M157.47,114.79h0.99l0.13,1.97h0.06c1.3-1.29,2.62-2.29,4.38-2.29c2.63,0,3.85,1.6,3.85,4.88v8.16h-1.17v-8.01 c0-2.7-0.85-3.98-2.9-3.98c-1.52,0-2.61,0.8-4.16,2.39v9.6h-1.17V114.79z" />
            <path d="M172.08,121.17c0-4.15,2.7-6.71,5.55-6.71c3.02,0,4.87,2.15,4.87,5.89c0,0.32-0.01,0.64-0.07,0.95h-9.15 c0.04,3.21,1.92,5.51,4.79,5.51c1.38,0,2.46-0.46,3.42-1.13l0.48,0.89c-1,0.63-2.15,1.25-4.01,1.25 C174.72,127.83,172.08,125.34,172.08,121.17z M181.4,120.35c0-3.25-1.47-4.87-3.76-4.87c-2.15,0-4.1,1.82-4.36,4.87H181.4z" />
            <path d="M185.96,126.07l0.68-0.85c1.08,0.9,2.23,1.61,4.04,1.61c2,0,3.04-1.17,3.04-2.51c0-1.6-1.65-2.3-3.12-2.83 c-1.92-0.68-4.06-1.46-4.06-3.64c0-1.83,1.45-3.39,4.1-3.39c1.36,0,2.71,0.56,3.64,1.32l-0.63,0.84 c-0.86-0.64-1.76-1.16-3.04-1.16c-1.99,0-2.88,1.14-2.88,2.32c0,1.45,1.5,2,3.02,2.58c1.97,0.75,4.16,1.4,4.16,3.88 c0,1.92-1.53,3.59-4.29,3.59C188.73,127.83,187.1,127.04,185.96,126.07z" />
            <path d="M198.28,126.07l0.68-0.85c1.08,0.9,2.23,1.61,4.04,1.61c2,0,3.04-1.17,3.04-2.51c0-1.6-1.65-2.3-3.12-2.83 c-1.92-0.68-4.06-1.46-4.06-3.64c0-1.83,1.45-3.39,4.1-3.39c1.36,0,2.71,0.56,3.64,1.32l-0.63,0.84 c-0.86-0.64-1.76-1.16-3.04-1.16c-1.99,0-2.88,1.14-2.88,2.32c0,1.45,1.5,2,3.02,2.58c1.97,0.75,4.16,1.4,4.16,3.88 c0,1.92-1.53,3.59-4.29,3.59C201.06,127.83,199.42,127.04,198.28,126.07z" />
            <path d="M217.96,126.81l10.06-15.71h-9.18v-1.05h10.69v0.71l-10.06,15.71h10.16v1.05h-11.67V126.81z" />
            <path d="M233.85,121.17c0-4.26,2.66-6.71,5.71-6.71s5.71,2.45,5.71,6.71c0,4.21-2.66,6.66-5.71,6.66S233.85,125.38,233.85,121.17z M244.05,121.17c0-3.35-1.91-5.67-4.48-5.67c-2.57,0-4.48,2.32-4.48,5.67s1.91,5.62,4.48,5.62 C242.14,126.8,244.05,124.52,244.05,121.17z" />
            <path d="M250.69,114.79h0.99l0.13,1.97h0.06c1.3-1.29,2.62-2.29,4.38-2.29c2.63,0,3.85,1.6,3.85,4.88v8.16h-1.17v-8.01 c0-2.7-0.85-3.98-2.9-3.98c-1.52,0-2.61,0.8-4.16,2.39v9.6h-1.17V114.79z" />
            <path d="M265.31,121.17c0-4.15,2.7-6.71,5.55-6.71c3.02,0,4.87,2.15,4.87,5.89c0,0.32-0.01,0.64-0.07,0.95h-9.15 c0.04,3.21,1.92,5.51,4.79,5.51c1.38,0,2.46-0.46,3.42-1.13l0.48,0.89c-1,0.63-2.15,1.25-4.01,1.25 C267.94,127.83,265.31,125.34,265.31,121.17z M274.63,120.35c0-3.25-1.47-4.87-3.76-4.87c-2.15,0-4.1,1.82-4.36,4.87H274.63z" />
          </g>
        </g>
        <g transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)">
          <g>
            <path d="M137.7,150.35h1.22l6.15,17.47h-1.29l-3.49-10.28c-0.71-2.07-1.33-3.9-1.94-6.03h-0.11c-0.61,2.13-1.22,3.96-1.94,6.03 l-3.54,10.28h-1.23L137.7,150.35z M134.35,160.81h7.83v1.02h-7.83V160.81z" />
            <path d="M149.6,164.31v-8.19h-1.99v-0.91l2.01-0.11l0.15-3.68h1v3.68h3.69v1.02h-3.69v8.28c0,1.63,0.41,2.72,2.16,2.72 c0.47,0,1.13-0.2,1.6-0.4l0.31,0.95c-0.74,0.26-1.55,0.48-2.08,0.48C150.32,168.14,149.6,166.59,149.6,164.31z" />
            <path d="M166.18,150.35h4.99c3.47,0,5.75,1.27,5.75,4.61c0,3.18-2.27,4.83-5.75,4.83h-3.77v8.02h-1.22V150.35z M170.86,158.77 c3.09,0,4.81-1.22,4.81-3.81c0-2.63-1.72-3.58-4.81-3.58h-3.46v7.39H170.86z M170.9,159.44l1.17-0.35l5.19,8.73h-1.42 L170.9,159.44z" />
            <path d="M181.6,150.98c0-0.66,0.49-1.04,1.07-1.04s1.07,0.38,1.07,1.04c0,0.61-0.49,1.04-1.07,1.04S181.6,151.59,181.6,150.98z M182.06,155.09h1.17v12.73h-1.17V155.09z" />
            <path d="M188.13,166.38l0.68-0.85c1.08,0.9,2.23,1.61,4.04,1.61c2,0,3.04-1.17,3.04-2.51c0-1.6-1.65-2.3-3.12-2.83 c-1.92-0.68-4.06-1.46-4.06-3.64c0-1.83,1.45-3.39,4.1-3.39c1.36,0,2.71,0.56,3.64,1.32l-0.63,0.84 c-0.86-0.64-1.76-1.16-3.04-1.16c-1.99,0-2.88,1.14-2.88,2.32c0,1.45,1.5,2,3.02,2.58c1.97,0.75,4.16,1.4,4.16,3.88 c0,1.92-1.53,3.59-4.29,3.59C190.91,168.14,189.27,167.35,188.13,166.38z" />
            <path d="M202.04,148.75h1.16v14.05h0.06l6.28-7.71h1.35l-4.15,5.04l4.84,7.69h-1.3l-4.22-6.82l-2.86,3.35v3.47h-1.16V148.75z" />
            <path d="M221.69,167.12l10.06-15.71h-9.18v-1.05h10.69v0.71l-10.06,15.71h10.16v1.05h-11.67V167.12z" />
            <path d="M237.59,161.48c0-4.26,2.66-6.71,5.71-6.71s5.71,2.45,5.71,6.71c0,4.21-2.66,6.66-5.71,6.66S237.59,165.69,237.59,161.48z M247.79,161.48c0-3.35-1.91-5.67-4.48-5.67c-2.57,0-4.48,2.32-4.48,5.67s1.91,5.62,4.48,5.62 C245.87,167.11,247.79,164.83,247.79,161.48z" />
            <path d="M254.43,155.09h0.99l0.13,1.97h0.06c1.3-1.29,2.62-2.29,4.38-2.29c2.63,0,3.85,1.6,3.85,4.88v8.16h-1.17v-8.01 c0-2.7-0.85-3.98-2.9-3.98c-1.52,0-2.61,0.8-4.16,2.39v9.6h-1.17V155.09z" />
            <path d="M269.04,161.48c0-4.15,2.7-6.71,5.55-6.71c3.02,0,4.87,2.15,4.87,5.89c0,0.32-0.01,0.64-0.07,0.95h-9.15 c0.04,3.21,1.92,5.51,4.79,5.51c1.38,0,2.46-0.46,3.42-1.13l0.48,0.89c-1,0.63-2.15,1.25-4.01,1.25 C271.68,168.14,269.04,165.64,269.04,161.48z M278.36,160.65c0-3.25-1.47-4.87-3.76-4.87c-2.15,0-4.1,1.82-4.36,4.87H278.36z" />
          </g>
        </g>
        <g transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)">
          <g>
            <path d="M132.07,192.77h1.28l2.19,10.29c0.4,1.9,0.8,3.79,1.2,5.69h0.11c0.41-1.9,0.87-3.8,1.34-5.69l2.74-10.29h1.24l2.74,10.29 c0.46,1.89,0.92,3.79,1.38,5.69h0.11c0.4-1.9,0.76-3.8,1.16-5.69l2.19-10.29h1.2l-3.91,17.47h-1.34l-3.19-11.99 c-0.31-1.37-0.62-2.57-0.92-3.93h-0.11c-0.31,1.37-0.66,2.56-0.97,3.93l-3.13,11.99h-1.33L132.07,192.77z" />
            <path d="M154.7,203.9c0-4.15,2.7-6.71,5.55-6.71c3.02,0,4.87,2.15,4.87,5.89c0,0.32-0.01,0.64-0.07,0.95h-9.15 c0.04,3.21,1.92,5.51,4.79,5.51c1.38,0,2.46-0.46,3.42-1.13l0.48,0.89c-1,0.63-2.15,1.25-4.01,1.25 C157.33,210.55,154.7,208.06,154.7,203.9z M164.02,203.07c0-3.25-1.47-4.87-3.76-4.87c-2.15,0-4.1,1.82-4.36,4.87H164.02z" />
            <path d="M170.16,208.53v-17.36h1.17v17.52c0,0.57,0.23,0.83,0.55,0.83c0.11,0,0.22,0,0.48-0.05l0.2,0.95 c-0.24,0.07-0.46,0.13-0.84,0.13C170.69,210.55,170.16,209.92,170.16,208.53z" />
            <path d="M178.03,208.53v-17.36h1.17v17.52c0,0.57,0.23,0.83,0.55,0.83c0.11,0,0.22,0,0.48-0.05l0.2,0.95 c-0.24,0.07-0.46,0.13-0.84,0.13C178.56,210.55,178.03,209.92,178.03,208.53z" />
            <path d="M185.9,197.51h0.99l0.13,1.97h0.06c1.3-1.29,2.62-2.29,4.38-2.29c2.63,0,3.85,1.6,3.85,4.88v8.16h-1.17v-8.01 c0-2.7-0.85-3.98-2.9-3.98c-1.52,0-2.61,0.8-4.16,2.39v9.6h-1.17V197.51z" />
            <path d="M200.52,203.9c0-4.15,2.7-6.71,5.55-6.71c3.02,0,4.87,2.15,4.87,5.89c0,0.32-0.01,0.64-0.07,0.95h-9.15 c0.04,3.21,1.92,5.51,4.79,5.51c1.38,0,2.46-0.46,3.42-1.13l0.48,0.89c-1,0.63-2.15,1.25-4.01,1.25 C203.15,210.55,200.52,208.06,200.52,203.9z M209.83,203.07c0-3.25-1.47-4.87-3.76-4.87c-2.15,0-4.1,1.82-4.36,4.87H209.83z" />
            <path d="M214.39,208.79l0.68-0.85c1.08,0.9,2.23,1.61,4.04,1.61c2,0,3.04-1.17,3.04-2.51c0-1.6-1.65-2.3-3.12-2.83 c-1.92-0.68-4.06-1.46-4.06-3.64c0-1.83,1.45-3.39,4.1-3.39c1.36,0,2.71,0.56,3.64,1.32l-0.63,0.84 c-0.86-0.64-1.76-1.16-3.04-1.16c-1.99,0-2.88,1.14-2.88,2.32c0,1.45,1.5,2,3.02,2.58c1.97,0.75,4.16,1.4,4.16,3.88 c0,1.92-1.53,3.59-4.29,3.59C217.17,210.55,215.53,209.76,214.39,208.79z" />
            <path d="M226.71,208.79l0.68-0.85c1.08,0.9,2.23,1.61,4.04,1.61c2,0,3.04-1.17,3.04-2.51c0-1.6-1.65-2.3-3.12-2.83 c-1.92-0.68-4.06-1.46-4.06-3.64c0-1.83,1.45-3.39,4.1-3.39c1.36,0,2.71,0.56,3.64,1.32l-0.63,0.84 c-0.86-0.64-1.76-1.16-3.04-1.16c-1.99,0-2.88,1.14-2.88,2.32c0,1.45,1.5,2,3.02,2.58c1.97,0.75,4.16,1.4,4.16,3.88 c0,1.92-1.53,3.59-4.29,3.59C229.49,210.55,227.85,209.76,226.71,208.79z" />
            <path d="M246.39,209.53l10.06-15.71h-9.18v-1.05h10.69v0.71l-10.06,15.71h10.16v1.05h-11.67V209.53z" />
            <path d="M262.29,203.9c0-4.26,2.66-6.71,5.71-6.71s5.71,2.45,5.71,6.71c0,4.21-2.66,6.66-5.71,6.66S262.29,208.1,262.29,203.9z M272.49,203.9c0-3.35-1.91-5.67-4.48-5.67c-2.57,0-4.48,2.32-4.48,5.67s1.91,5.62,4.48,5.62 C270.57,209.52,272.49,207.25,272.49,203.9z" />
            <path d="M279.12,197.51h0.99l0.13,1.97h0.06c1.3-1.29,2.62-2.29,4.38-2.29c2.63,0,3.85,1.6,3.85,4.88v8.16h-1.17v-8.01 c0-2.7-0.85-3.98-2.9-3.98c-1.52,0-2.61,0.8-4.16,2.39v9.6h-1.17V197.51z" />
            <path d="M293.74,203.9c0-4.15,2.7-6.71,5.55-6.71c3.02,0,4.87,2.15,4.87,5.89c0,0.32-0.01,0.64-0.07,0.95h-9.15 c0.04,3.21,1.92,5.51,4.79,5.51c1.38,0,2.46-0.46,3.42-1.13l0.48,0.89c-1,0.63-2.15,1.25-4.01,1.25 C296.38,210.55,293.74,208.06,293.74,203.9z M303.06,203.07c0-3.25-1.47-4.87-3.76-4.87c-2.15,0-4.1,1.82-4.36,4.87H303.06z" />
          </g>
        </g>
        {/* <text fontSize="2em" transform=""className="st15 st16">My Life Wheel</text> */}
        <g transform="matrix(0.482864, 0, 0, 0.482864, 25.796816, 0.258568)">
          <title>Lines</title>
          <line
            stroke="black"
            x1="434.711"
            y1="473.846"
            x2="319.646"
            y2="475.126"
          />
          <line
            stroke="black"
            x1="484.199"
            y1="630.938"
            x2="438.928"
            y2="630.286"
          />
          <line
            stroke="black"
            x1="375.159"
            y1="632.093"
            x2="314.119"
            y2="632.673"
          />
          <line
            stroke="black"
            x1="535.31"
            y1="703.926"
            x2="475.744"
            y2="703.926"
          />
          <line
            stroke="black"
            x1="410.897"
            y1="704.975"
            x2="316.531"
            y2="704.975"
          />
          <line
            stroke="black"
            x1="595.958"
            y1="766.831"
            x2="533.315"
            y2="767.503"
          />
          <line
            stroke="black"
            x1="449.007"
            y1="769.387"
            x2="315.901"
            y2="769.387"
          />
          <line
            stroke="black"
            x1="686.18"
            y1="821.194"
            x2="602.278"
            y2="821.194"
          />
          <line
            stroke="black"
            x1="506.742"
            y1="821.484"
            x2="315.807"
            y2="821.484"
          />
          <line
            stroke="black"
            x1="825.02"
            y1="855.507"
            x2="659.643"
            y2="855.507"
          />
          <line
            stroke="black"
            x1="556.09"
            y1="859.69"
            x2="316.305"
            y2="859.69"
          />
          <line
            stroke="black"
            x1="452.452"
            y1="555.24"
            x2="410.583"
            y2="555.912"
          />
          <line
            stroke="black"
            x1="354.012"
            y1="556.781"
            x2="315.074"
            y2="556.781"
          />
        </g>
      </svg>

      {/* 
      <Modal
        title="Image Preview"
        centered
        visible={modal.show}
        onOk={() => setModal({ show: false })}
        onCancel={() => setModal({ show: false })}
        width="60vw"
        footer={false}
      >
        <img src={modal.image} alt="info" style={{ width: '100%' }} />
      </Modal> */}
    </>
  );
};

export default WheelSVG;
