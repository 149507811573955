import React from 'react';
import styles from './styles.module.css';
import Step from './Step';

const Stepper = ({ steps, currentStep, fontSize, fontColor, themeColor }) => {
  const defualtStep = 1;

  return (
    <div className={styles.Stepper}>
      {steps?.map((step, i) => (
        <Step
          key={i}
          label={step.label}
          showLine={i > 0}
          showVIcon={currentStep > i + defualtStep - 1}
          isCompleted={currentStep >= i + defualtStep}
          fontSize={fontSize}
          color={step.color}
          fontColor={fontColor}
          themeColor={themeColor}
        />
      ))}
    </div>
  );
};

export default Stepper;
