const Step5 = () => (
  <>
    <ul>
      <li>Human Potential Score (HPS) at least 60</li>
      <li>GREEN at least 6</li>
      <li>Already be a Yellow Band member</li>
    </ul>
  </>
);

export default Step5;
