import request from '../../shared/lib/api';

const createNewAnswer = (data) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/answers`,
    method: 'POST',
    data: {
      ...data,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const editAnswer = (id, data) => {
  // console.log(data);
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/answers/${id}`,
    method: 'PATCH',
    data: {
      ...data,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const getAllAnswersById= (questionId, questionGroupId) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/answers`,
    method: 'GET',
    params: {
      ...(questionId && { questionId }),
      ...(questionGroupId && { questionGroupId }),
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const getAllAnswers= () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/answersAll`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};


const deleteAnswer = (id) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/answers/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const AnswerService = {
  createNewAnswer,
  editAnswer,
  deleteAnswer,
  getAllAnswersById,
  getAllAnswers,
};

export default AnswerService;
