import request from '../../shared/lib/api';

const addLifeMastery = (body) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/lifeMastery`,
    method: 'POST',
    data: {
      ...body,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const editLifeMastery = (body) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/lifeMastery`,
    method: 'PUT',
    data: {
      ...body,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const deleteLifeMastery = (body) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/lifeMastery`,
    method: 'DELETE',
    data: {
      ...body,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};


const getAllLifeMasteries = (step) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/lifeMastery`,
    method: 'GET',
    params: {
      step,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};
const getAllLifeMasteriesWithGoalId = (step,goalId) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/lifeMasteryGoals`,
    method: 'GET',
    params: {
      step,
      goalId
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const LifeMasteryService = {
  addLifeMastery,
  getAllLifeMasteries,
  editLifeMastery,
  deleteLifeMastery,
  getAllLifeMasteriesWithGoalId
};

export default LifeMasteryService;
