import { Button, Collapse, Table } from 'antd';
import { useEffect, useState } from 'react';
import { EyeFilled } from '@ant-design/icons';

const { Panel } = Collapse;

const MyCoreValuesHistoryTable = ({  onEditClick, wholedata }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setData(wholedata);
  }, []);


  const columns = [
    {
      title: 'Date and time',
      dataIndex: 'formateddate',
      width: '80%',
    },

    {
      title: 'Action',
      dataIndex: 'id',
      render: (_, key) => {
        return (
          <Button
            type="primary"
            icon={<EyeFilled />}
            onClick={() => onEditClick(key)}
          >
            View and edit
          </Button>
        );
      },
    },
  ];

  return (
    <Collapse accordion style={{marginTop : '3%'}}>
      <Panel header="History" key="1">
        <Table
          loading={loading}
          bordered
          dataSource={data}
          columns={columns}
        />
      </Panel>
    </Collapse>
  );
};

export default MyCoreValuesHistoryTable;
