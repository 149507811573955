import request from '../../shared/lib/api';

const addGoal = (body) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/addgoal`,
    method: 'POST',
    data: {
      ...body,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const updateGoal = (body) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/updategoal`,
    method: 'PUT',
    data: {
      body,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const deleteGoal = (body) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/deletegoal`,
    method: 'DELETE',
    data: {
      body,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};


const getAllGoals = (step) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return request({
    url: `/mygoals`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const MyGoalsService = {
    getAllGoals,
    deleteGoal,
    updateGoal,
    addGoal
};

export default MyGoalsService;
