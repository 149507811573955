import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, children, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isLoggedIn ? (
          <Component {...props} {...rest} /> || children
        ) : (
          <Redirect
            to={{
              pathname:'/unauthorized',
              state: { form: props.location, redirection: props.location, query : props.location.search  },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
