import { Result, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import { loginUser } from '../../../store/actions/authActions';

const Login = () => {
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();

  const wpUserId = query.get('code').replace(' ', '+');
  
  useEffect(() => {
    if (auth.error) {
      history.push('/unauthorized');
    }
  }, [auth.error, history]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      const redirectTo = auth?.redirectTo ? auth?.redirectTo : '/';
      history.push(location.state?.form.pathname || redirectTo);
    }
  }, [auth, location, history]);

  useEffect(() => {
    dispatch(loginUser(wpUserId));
  }, [wpUserId, dispatch]);

  return (
    <Result icon={<Spin size="large" />} title="Logging in, please wait..." />
  );
};

export default Login;
