const Step8 = () => (
  <>
    <ul>
      <li>Human Potential Score (HPS) at least 75</li>
      <li>GREEN at least 9</li>
      <li>Already be a Indigo Band member</li>
      <li>Involved in a Community/Social Project</li>
    </ul>
  </>
);

export default Step8;
