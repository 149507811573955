import {
  Alert,
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Slider,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductsService from '../../../services/api/ProductsService';
import { refreshUser } from '../../../store/actions/authActions';

import axios from 'axios';

import ProductCard from '../components/ProductCard';
import FormData from 'form-data';
import { useHistory } from 'react-router-dom';

const form = new FormData();
const { Paragraph } = Typography;

const CreatePointModal = ({
  product,
  onCancel,
  successCallback = () => null,
}) => {
  const [loading, setLoading] = useState(false);
  const [codeDetail, setCodeDetail] = useState(undefined);
  const history = useHistory();

  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let price = product && product.price ? product.price : 0;
  let regular_price =
    product && product.regular_price ? product.regular_price : 0;
  let maxOfferCanApply = Math.min(price * 0.1, user.credit);
  maxOfferCanApply = maxOfferCanApply > 1 ? maxOfferCanApply : 0;
  let finalPrice = price - maxOfferCanApply;
  useEffect(() => {
    if (product) {
      form.setFieldsValue({ amount: maxOfferCanApply, productId: product.id });
    }
  }, [maxOfferCanApply, form, product]);

  const marks = {
    0: <div style={{ paddingLeft: '14px' }}>0%</div>,
    [`${maxOfferCanApply}`]: `100%`,
  };
  // let asdasd = product?.name;
  const createCoupon = async (userInputs) => {
    setLoading(true);
    try {
      const res = await ProductsService.createProductCoupon(userInputs);
      setCodeDetail({ ...res.data.data });
      dispatch(refreshUser());
    } catch (error) {}
    setLoading(false);
  };

  // let addtoCart = async (prodid) => {

  //   // let url = `https://heatwellbeing.com/product/${asdasd}/`;
  //   const response = await axios.post(
  //     'https://heatwellbeing.com/product/life-coaching/',
  //     {
  //       headers: {
  //         'quantity' : '1',
  //         'add-to-cart': `${prodid}`,
  //         'accept-language': 'en-US,en;q=0.9',
  //         'cache-control': 'max-age=0',
  //       },
  //     },
  //   );
  // };
  // const gotoprod = (
  //   <Button
  //     type="primary"
  //     style={{ marginLeft: '2%', marginBottom: '5%' }}
  //     onClick={async () => {
  //       await addtoCart(product.id);
  //       history.push({
  //         pathname: 'https://heatwellbeing.com/checkout',
  //       });
  //     }}
  //   >
  //     add to cart
  //   </Button>
  // );

  const formComponent = (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" name="form_in_modal">
        <Row>
          <Typography.Title level={4}>
            <span style={{ textDecoration: 'line-through', color: ' red' }}>
              {' '}
              {regular_price == price
                ? null
                : regular_price.toFixed(2) + '$'}{' '}
            </span>{' '}
            {price}$ - {maxOfferCanApply}$ ={' '}
            <span style={{ color: ' green' }}> {finalPrice.toFixed(2)}$ </span>
          </Typography.Title>
        </Row>
        <Row gutter={[1]} justify="space-between">
          <Col span={18}>
            <Form.Item
              name="amount"
              label="Use my credit"
              rules={[{ required: true }]}
            >
              <Slider marks={marks} min={0} max={maxOfferCanApply} step={0} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="amount" label=" ">
              <InputNumber
                min={maxOfferCanApply}
                max={maxOfferCanApply}
                step={0}
                style={{ width: '100%' }}
                addonAfter="$"
              />
            </Form.Item>
            <Form.Item noStyle name="productId" />
          </Col>
        </Row>
      </Form>
    </Spin>
  );

  const codeElement =
    codeDetail && product ? (
      <div className="coupon-code-section" style={{ marginTop: '30px' }}>
        <Alert
          message={`Coupon successfully created: ${codeDetail.amount.toFixed(
            2,
          )}$`}
          description='Copy coupon code below, then click on "Go to Product Page"'
          type="success"
          showIcon
        />

        <Row justify="space-between">
          <Paragraph style={{ fontSize: '19px' }} copyable>
            {codeDetail.code}
          </Paragraph>
          <Button type="primary" href={product.permalink}>
            Go to product page
          </Button>
          {/* {gotoprod} */}
        </Row>
      </div>
    ) : 
    formComponent
  //   maxOfferCanApply == 0 ? (  <Button type="primary" href={product?.permalink}>
  //   Go to product page
  // </Button>) :formComponent

 

  return (
    <Modal
      visible={!!product}
      title="Create Coupon"
      okText="Submit"
      cancelText="Cancel"
      onCancel={() => {
        setCodeDetail(undefined);
        onCancel();
      }}
      // onOk={}
      confirmLoading={false}
      footer={
        codeDetail ? null : (
          <Button
            loading={loading}
            type="primary"
            onClick={() => form.validateFields().then(createCoupon)}
          >
            Submit
          </Button>
        )
      }
    >
      <ProductCard {...product} showButton={false} />
      <div style={{ marginBottom: '30px' }} />

      {codeElement}
    </Modal>
  );
};

export default CreatePointModal;
