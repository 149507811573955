import { Card, Collapse, Form, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../../components/layout/page-title/PageTitle';
import Stepper from '../../../components/navigation/stepper';
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
} from './steps';
import './styles.less';

const { Panel } = Collapse;

const MyLifeMasteryRoadMapPage = () => {
  const auth = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  const steps = useMemo(
    () => [
      {
        title: 'White',
        form: Step1,
      },
      {
        title: 'Red',
        form: Step2,
      },
      {
        title: 'Orange',
        form: Step3,
      },
      {
        title: 'Yellow',
        form: Step4,
      },
      {
        title: 'Green',
        form: Step5,
      },
      {
        title: 'Blue',
        form: Step6,
      },
      {
        title: 'Indigo',
        form: Step7,
      },
      {
        title: 'Violet',
        form: Step8,
      },
      {
        title: 'Black',
        form: Step9,
      },
    ],
    [],
  );

  const currentStep = steps.findIndex(
    (item) => item.title.toLowerCase() === auth?.user?.rank.toLowerCase(),
  );

  const desc = steps[currentStep].description ? (
    <Typography.Text className="steps-description" type="secondary">
      {steps[currentStep].description}
    </Typography.Text>
  ) : null;

  const StepFormItem = steps[currentStep].form ? steps[currentStep].form : null;

  const hasUserPlus = auth?.user?.plus;

  return (
    <div className="life-road-page">
      <PageTitle title="My Life Map (Ranks)" />

      <Stepper
        fontSize="15px"
        fontColor="#18aed6"
        steps={steps.map((step, index) => ({
          label: `${step.title} ${
            hasUserPlus && index === currentStep ? '+' : ''
          }`,
          color:
            steps[index]?.title?.split(' ')[0]?.toLowerCase() === 'white'
              ? '#d9d9d9'
              : steps[index]?.title?.split(' ')[0]?.toLowerCase(),
        }))}
        currentStep={currentStep + 1}
      />

      <Form form={form} layout="vertical">
        <div className="steps-content">
          {desc}
          <div style={{ paddingTop: '20px' }}>{<StepFormItem />}</div>
        </div>
      </Form>

      <Collapse accordion style={{ marginTop: '20px' }}>
        <Panel header="Requirements" key="1">
          {steps.map((step, index) => {
            const StepFormItem = steps[index].form ? steps[index].form : null;

            return (
              <Card style={{ marginBottom: '20px' }}>
                <Typography.Title level={5}>{step?.title}</Typography.Title>
                <StepFormItem />
              </Card>
            );
          })}
        </Panel>
      </Collapse>
    </div>
  );
};

export default MyLifeMasteryRoadMapPage;
