import { Carousel, Row, Spin, Typography, Button,  } from 'antd';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import PageTitle from '../../components/layout/page-title/PageTitle';
import useApi from '../../hooks/useApi';
import ProductsService from '../../services/api/ProductsService';
import { Interweave } from 'interweave';
import { useSelector } from 'react-redux';
import useQuery from '../../hooks/useQuery';

import CreateCouponModal from './modals/CreateCouponModal';


const ProductDetailPage = () => {
  const { user } = useSelector((state) => state.auth);
  const [couponProduct, setCouponProduct] = useState(undefined);

  const { id } = useParams();

  // const query = useQuery();
  // const redirect = query.get('redirect');

  const { loading, data = { data: { product: undefined } } } = useApi(
    ProductsService.getProduct,
    undefined,
    id,
  );
  const imageurl = data.data.product?.images[0]?.src;

  const { product } = data.data;

  // console.log('image url ', imageurl);
  const loadingComponent = loading ? (
    <Spin size="large" style={{ display: 'flex', justifyContent: 'center' }} />
  ) : null;

  const imagesElement = product
    ? product.images.map((img) => (
        <div>
          <img
            style={{ height: '300px', width: '100%', objectFit: 'cover' }}
            src={data.data.product?.images[0]?.src}
          />
        </div>
      ))
    : null;

  const safePrice = product && product.price ? product.price : 0;
  const maxOfferCanApply = Math.min(safePrice * 0.1, user.credit);

  const productLayout = product ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <PageTitle title={product?.name} />
      {/* <Carousel autoplay>{imagesElement}</Carousel> */}
      <Row style={{ marginTop: '20px' }} justify="space-between" align="middle">
        <div>
          {imagesElement}
          {/* <img
            style={{ height: '300px', width: '100%', objectFit: 'cover' }}
            alt={img.name}
            src={img.src}
          /> */}
          <Typography.Text
            style={{
              color: '#cf1322',
              textDecoration: 'line-through',
              fontSize: '1.2rem',
              paddingRight: '10px',
            }}
          >
            {safePrice}$
          </Typography.Text>

          <Typography.Text
            style={{
              color: '#3f8600',
              fontSize: '1.2rem',
            }}
            strong
          >
            
            {(safePrice - maxOfferCanApply).toFixed(2)}$
          </Typography.Text>
        </div>
      </Row>
      <Row>
      <Button type="primary" onClick={(e) => {setCouponProduct(product)}}>Buy</Button>

      </Row>
      <Interweave content={product?.description} />

      
      <CreateCouponModal
        product={couponProduct}
        onCancel={() => setCouponProduct(undefined)}
      />
    </div>
  ) : null;

  return (
    <div>
      {loadingComponent}
      {productLayout}
    </div>
  );
};

export default ProductDetailPage;
