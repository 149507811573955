const Step4 = () => (
  <>
    <ul>
      <li>Human Potential Score (HPS) at least 55</li>
      <li>GREEN at least 5</li>
      <li>Already be a Orange Band member</li>
    </ul>
  </>
);

export default Step4;
