import { Form, Select } from 'antd';
import coreValues from '../../../../shared/constants/core-values';

const { Option } = Select;

const Step1 = ({ form }) => {




  return (
    <>
      <Form.Item
        name="coreValues"
        label="Choose all the Values that are aligned with YOU from the list below. What are the most important things to you? What gives meaning to your life? What helps you become a better person?"
        rules={[{ required: true }, { type: 'array', min: 10 }]}
      >
        <Select mode="multiple">
          {coreValues.map((value) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default Step1;
