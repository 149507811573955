import { Button, Form, Input, Typography,Spin,message} from 'antd';
import PageTitle from '../../components/layout/page-title/PageTitle';
import moment from 'moment';
import { React, useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import UserService from '../../services/api/UserService';
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 7,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 3,
  },
};

const Profile = () => {

  const auth = useSelector((state) => state.auth);
  const [createLoading, setCreateLoading] = useState(false);


  const { loading, user } = auth;

  // let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;

  const [form] = Form.useForm();

  const onFinish = async (values) => {

    Object.keys(values).forEach(key => values[key] === undefined ? delete values[key] : {});

    setCreateLoading(true);
    try {
      
      await UserService.updateProfile(values);
      message.success('Your profile has been updated.');
      // resetQuestionAndAnswer();
    } catch (error) {
      message.error(error.message);
    }
    setCreateLoading(false);
  };






  useEffect(() => {

    onFill();
  }, []);

  const onFill = () => {
    form.setFieldsValue({
      firstName: user.firstName,
      lastName: user.lastName,
      postalCode: user.postalCode,
      email: user.email,
    });
  };

  return (
    <>
      <PageTitle title="Profile" />
      <Spin spinning={createLoading}>

      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        
        <Form.Item name="email" label="Email">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: false,
              message: 'Please enter your first name',
            },
            { whitespace: true },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last name"
          rules={[
            {
              required: false,
              message: 'Please enter your last name',
            },
            { whitespace: true },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          name="Password"
          label="Password"
          rules={[
            {
              required: false,
              message: 'Please enter your new password',
            },
            { whitespace: true },
            {
              pattern: passwordRegex,
              message:
                'Password must be more than 8 chars, at least one number, at least one special character',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm password"
          dependencies={['Password']}
          rules={[
            {
              required: false,
              message: 'Please enter your new password',
            },
            { whitespace: true },
            ({ getFieldValue }) => ({
              validator(rules, value) {
                if (!value || getFieldValue('Password') == value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    'The two password you entered does not match.',
                  );
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item> */}

        <Form.Item
          name="postalCode"
          label="Postal Code"
          rules={[
            {
              required: true,
              message: 'Please enter your postal code',
            },
            { whitespace: true },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item {...layout} name="heatpoints" label="Role">
          <Typography.Text strong>{user.role}</Typography.Text>
        </Form.Item>

        <Form.Item name="rank" label="Rank:">
          {' '}
          <Typography.Text strong>{user.rank}</Typography.Text>
          <Typography.Text strong> {user.plus ? '+' : ''}</Typography.Text>
        </Form.Item>
        <Form.Item {...layout} name="heatpoints" label="ID">
          <Typography.Text strong>{user.serial+1000}</Typography.Text>
        </Form.Item>
        <Form.Item name="registerdate" label="Register date:">
          {' '}
          <Typography.Text strong>
            {moment(user.createdAt).format('DD/MM/YYYY')}
          </Typography.Text>
        </Form.Item>
        <Form.Item name="credit" label="Credit:">
          {' '}
          <Typography.Text strong>${user.credit?.toFixed(2)}</Typography.Text>
        </Form.Item>
        <Form.Item {...layout} name="heatpoints" label="HEAT Points">
          <Typography.Text strong>{user.points}</Typography.Text>
        </Form.Item>
      
       
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
   
        </Form.Item>
      </Form>
      </Spin>
    </>
  );
};

export default Profile;
