import { Form, Modal, Select } from 'antd';

const MyCareTeamModal = ({
  visible,
  handleOk,
  handleCancel,
  confirmLoading,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="My Care Team"
      visible={visible}
      onOk={() => handleOk(form.getFieldsValue())}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        <Form.Item name="health-providers" label="Health & Wellness Providers">
          <Select mode="tags" />
        </Form.Item>

        <Form.Item name="coaches" label="Coaches & Mentors">
          <Select mode="tags" />
        </Form.Item>

        <Form.Item name="care-givers" label="Care Givers">
          <Select mode="tags" />
        </Form.Item>

        <Form.Item name="accountability-buddies" label="Accountability Buddies">
          <Select mode="tags" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MyCareTeamModal;
