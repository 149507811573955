import request from '../../shared/lib/api';

const createNewContract = (type) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return request({
    url: `/contracts`,
    method: 'POST',
    data: {
      type,
    },
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
};

const ContractService = {
  createNewContract,
};

export default ContractService;
